import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
  styles: {
    global: {
      'html, body': {
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: '500',
        bg: 'white',
        color: 'black',
      },
      a: {
        color: 'white',
        _hover: {
          textDecoration: 'underline',
        },
      },
      p: {
        color: "#024856",
      },
      button: {
        color: 'white'
      },
      h1: {
        fontFamily: `'Lato', sans-serif`,
        fontWeight: 'bold', 
        // fontStyle: 'italic',
      },
      h2: {
        fontFamily: `'Lato', sans-serif`,
        fontWeight: 'bold', 
        // fontStyle: 'italic',
      },
    },
  },
  colors: {
    brand: {
      1: "#007188",
      2: "#024856",
      3: "#5AB3C5",
    },
  },
})

export default theme