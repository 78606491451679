import "./App.css";
import BlogPage from "./components/blog/BlogPage";
import SingleBlogPage from "./components/blog/SingleBlogPage";
import LandingPage from "./components/landingPage/LandingPage";
import { HelmetProvider } from "react-helmet-async";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:title" element={<SingleBlogPage />} />
        <Route path="*" element={<div>Oldal nem található</div>} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
