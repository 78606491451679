import {
  Box,
  Text,
  Icon,
  VStack,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";
import { FaEnvelope, FaCalendarAlt } from "react-icons/fa";
import { mainColor } from "./LandingPage";

const Contacts = () => {
  return (
    <Box id="contact" p={4} maxW="1400px" mx="auto">
      <Text fontSize="4xl" fontWeight="bold" textAlign="center" mb={8}>
        Kapcsolat
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
        {/* Email Box */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={5}
          borderWidth={1}
          borderRadius="md"
          boxShadow="lg"
          bg="white"
        >
          <VStack spacing={4} align="center">
            <Icon as={FaEnvelope} w={10} h={10} color={mainColor} />
            <Text fontSize="xl" fontWeight="bold">
              Email
            </Text>
            <Text>Kérdésed vagy észrevételed van, keress minket az alábbi email címen.</Text>
            <Link href="mailto:info@wobble.hu" color={mainColor} isExternal fontWeight="bold">
              info@wobble.hu
            </Link>
          </VStack>
        </Box>

        {/* Online Booking Box */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={5}
          borderWidth={1}
          borderRadius="md"
          boxShadow="lg"
          bg="white"
        >
          <VStack spacing={4} align="center">
            <Icon as={FaCalendarAlt} w={10} h={10} color={mainColor} />
            <Text fontSize="xl" fontWeight="bold">
              Online időpont foglalás
            </Text>
            <Text fontSize="md">
              Nem vagy biztos benne, hogy neked megéri-e? Esetleg kérdésed van? Elakadtál a használatban?
              Gyere és beszéljünk! Ingyenes konzultáción segítek megismerni, mit
              is tud nyújtani a Wobble, hogyan legyen még szebb és figyelem felhívóbb.
            </Text>
            <Link href="https://book.wobble.hu" color={mainColor} isExternal fontWeight="bold">
              Foglalj időpontot
            </Link>
          </VStack>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Contacts;
