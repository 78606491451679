import online_jelenlet from "../../assets/blog_images/online-jelenlet-idopontfoglalo.png"
import manage_business from "../../assets/blog_images/manage-business.png"
import registracion1 from "../../assets/blog_images/registration/wobble_regisztracio1.png"
import registracion2 from "../../assets/blog_images/registration/wobble_regisztracio2.png"
import registracion3 from "../../assets/blog_images/registration/wobble_regisztracio3.png"
import package1 from "../../assets/blog_images/registration/wobble_valassz_csomagot.png"
import stripe from "../../assets/blog_images/registration/wobble_stripe.png"
import data1 from "../../assets/blog_images/registration/wobble_adatok1.png"
import data2 from "../../assets/blog_images/registration/wobble_adatok2.png"
import register_settings1 from "../../assets/blog_images/registration/wobble_beallitasok1.png"
import register_settings2 from "../../assets/blog_images/registration/wobble_beallitasok2.png"
import weblap from "../../assets/blog_images/registration/wobble_foglalasi_oldal.png"
import settings1 from "../../assets/blog_images/settings/wobble_beallitas_1.png"
import settings2 from "../../assets/blog_images/settings/wobble_beallitas_2.png"
import settings3 from "../../assets/blog_images/settings/wobble_beallitas_3.png"
import settings4 from "../../assets/blog_images/settings/wobble_beallitas_4.png"
import settings5 from "../../assets/blog_images/settings/wobble_beallitas_5.png"
import settings6 from "../../assets/blog_images/settings/wobble_beallitas_6.png"
import settings7 from "../../assets/blog_images/settings/wobble_beallitas_7.png"
import url from "../../assets/blog_images/settings/wobble_url.png"
import wobble_aboutus from "../../assets/blog_images/settings/wobble_idopontfoglalo.png"
import ai_header from "../../assets/blog_images/ai-hasznalata.jpg"
import ai1 from "../../assets/blog_images/AI-hasznald-az-AI-t.png"
import ai2 from "../../assets/blog_images/vallalkozo-no-laptopon-dolgozik.png"
import optimiz_header from "../../assets/blog_images/optimalizald-vallalkozasod.png"
import optimiz1 from "../../assets/blog_images/google-naptar-szinkronizacio.png"
import optimiz2 from "../../assets/blog_images/idopontfoglalo-rendszer-szepsegiparban.png"
import optimiz3 from "../../assets/blog_images/wobble-fooldal.png"
import phisingMeta1 from "../../assets/blog_images/phising/meta-adathalasz-email.png"
import phisingMeta2 from "../../assets/blog_images/phising/meta-adathalasz-email-1.png"
import phisingMeta3 from "../../assets/blog_images/phising/meta-adathalasz-email-2.png"
import phisingMeta4 from "../../assets/blog_images/phising/meta-adathalasz-email-3.png"
import phisingMeta5 from "../../assets/blog_images/phising/meta-adathalasz-email-4.png"
import phisingMeta6 from "../../assets/blog_images/phising/meta-adathalasz-email-5.png"
import phisingMetaLink1 from "../../assets/blog_images/phising/meta-adathalasz-email-link.png"
import phisingMetaLink2 from "../../assets/blog_images/phising/meta-adathalasz-email-link-2.png"
import uzenet1 from "../../assets/blog_images/phising/meta-adathalasz-uzenet.png"
import uzenet2 from "../../assets/blog_images/phising/adathalasz-uzenet-dpd.png"
import uzenet3 from "../../assets/blog_images/phising/adathalasz-uzenet-posta.png"
import uzenet4 from "../../assets/blog_images/phising/adathalasz-uzenet.png"
import cyber_secutiry from "../../assets/blog_images/cyber-security.png"


export type BlogPost = {
  id: number;
  title: string;
  thumbnail: string;
  slug: string;
  content: Array<{
    type: "header" | "text" | "image" | "link" | "video" | "linkText";
    content: string;
    link?: string;
  }>;
};


const slugify = (title: string) => {
  return title
    .toLowerCase()
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ö/g, 'o')
    .replace(/ő/g, 'o')
    .replace(/ú/g, 'u')
    .replace(/ü/g, 'u')
    .replace(/ű/g, 'u')
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const blogPosts: BlogPost[] = [
  
  {
    id: 1,
    title: "Wobble regisztráció lépésről lépésre",
    thumbnail: registracion2,
    slug: slugify("Wobble regisztráció lépésről lépésre"),
    content: [
      { type: "text", content: "Az online megjelenés, főleg a mai digitális világban, elengedhetetlen. Sokkal nagyobb bizalmat kelt leendő ügyfeleidben egy szépen megalkotott weblap, összeszedett információk, szépen szerkesztett képek és videók. Persze egy induló vállalkozásnál sok mindennel kell szembenézni, és rengeteget kell tanulni hozzá. Ehhez szeretnénk segítséget nyújtani a Wobble időpontfoglaló alkalmazásunkkal, ahol online megjelenés, időpontfoglaló rendszer, ügyfélkezelés, valamint email marketing és blog írás is lehetséges." },
      { type: "header", content: "Regisztráció lépésről lépésre:" },
      { type: "text", content: "1. A alábbi két link megnyitja a regisztrációs oldalt. Add meg az email címed és egy jelszót, vagy lépj be a Google fiókoddal. Google fiókkal később be tudod állítani, hogy folyamatos szinkronizálva legyenek a foglalások naptáradban. A fiók létrehozása gomb megnyomásával tudomásul vetted és elfogadtad szerződési feltételeinket és adatkezelési tájékoztatónkat. Ezután az oldal tölteni kezd." },
      { type: "link", content: "https://app.wobble.hu/" },
      { type: "text", content: " vagy " },
      { type: "link", content: "https://app.wobble.hu/signup_page" },
      { type: "image", content: registracion1 },
      { type: "image", content: registracion2 },
      { type: "image", content: registracion3 },
      { type: "text", content: "2. Válassz csomagot. Ne ijedj meg, nem fizetsz semmit, még kártyaadatot sem kérünk! Ezután megjelenik egy megerősítő oldal, ahol láthatod az ingyenes időszak meddig tart majd."},
      { type: "image", content: package1 },
      { type: "image", content: stripe },
      { type: "text", content: "3.  Következő oldalon add meg a neved, telefonszámod, vállalkozásod nevét, valamint egy foglalási oldal URL nevet (kisbetű és kötőjel használatával pl kati-viragbolt), amivel egyedibbé teheted foglalási oldalad. Ezeket később is meg tudod adni."},
      { type: "image", content: data1 },
      { type: "image", content: data2 },
      { type: "text", content: '4. Kész is a fiókod! 🥳 Már használhatsz is minden funkciót! Töltsd ki a beállítások menüt, majd a Foglalási oldal gomb segítségével teszteld foglalási oldalad. Állíts be mindent személyre szabottan, hogy minél inkább tükrözze vállalkozásod.'},
      { type: "image", content: register_settings1 },
      { type: "image", content: register_settings2 },
      { type: "image", content: weblap },
      { type: "text", content: "Gyere és próbálod ki alkalmazásunkat kártyamentes regisztrációval 14 napig ingyen! Ha úgy érzed, szükséged volna egy kis útmutatásra, elvesztél a rengeteg tennivaló között, foglalj hozzánk ingyenes konzultációra időpontot, és beszéljünk hogyan tehetnénk vállalkozásod sikeressé. Nézd meg a beállítások cikkünket is az oldalad minél jobb személyre szabása érdekében." }
    ]
  },

  {
    id: 2,
    title: "Wobble időpontfoglaló személyre szabása",
    thumbnail: wobble_aboutus,
    slug: slugify("Wobble időpontfoglaló személyre szabása"),
    content: [
      { type: "header", content: "Hozd ki a legtöbbet az időpontfoglaló oldaladból!"},
      { type: "text", content: "Amikor vállalkozást indítasz, az online megjelenésed az első, amit ügyfeleid meglátnak - épp ezért nagyon fontos, hogy az tükrözze vállalkozásod stílusát és profizmusát. Ma már nem elég egy összevissza információkat tartalmazó Facebook-oldal, valamint weboldal hiánya nem sugároz megbízhatóságot. Ehelyett egy átgondolt, egységes online jelenlét sokkal jobb benyomást kelt, ami növelheti ügyfeleid bizalmát és foglalási hajlandóságát." },
      { type: "text", content: "Tudom, induló vállalkozásként kevés tőke áll rendelkezésedre, hogy weblapot készíttess, vagy előfizess különféle eszközökre. Ráadásul minden új dolgot megtanulni időigényes is. Ezt szeretnénk megkönnyíteni neked a Wobble időpontfoglaló és online megjelenés rendszerünkkel. Lássuk, hogyan állíthatod be a lehető legjobban az időpontfoglaló felületedet:" },
      { type: "header", content: "Oldalad megjelenésének beállítása lépésről lépésre:" },
      { type: "text", content: "1. Az első és legfontosabb lépés, hogy kiválasztod a borítóképet, a profilképet és az oldalad színeit. Ha nem töltesz fel borítóképet, a fejléc automatikusan a választott két szín átmenetét fogja megjeleníteni. Miután beállítottad, mentsd el, majd kattints felül a Foglalási oldal gombra, hogy megnézd, mit látnak az ügyfeleid. Ha valami nem tökéletes, módosítsd a képeket, színeket, és teszteld újra." },
      { type: "text", content: "Admin beállítások" },
      { type: "image", content: settings1 },
      { type: "text", content: "Foglalási oldalad" },
      { type: "image", content: wobble_aboutus },
      { type: "text", content: "2. Profiladatok kitöltése. Az itt megadott adatok - név, email cím, telefonszám, foglalkozás, vállalkozás neve - jelennek meg az Rólam részben a foglalási oldaladon. Ellenőrizd, hogy megfelelően látszódnak-e. Ha valami nem helyes, javítsd, majd mentsd el a változtatásokat."},
      { type: "image", content: settings2 },
      { type: "text", content: "3. Vállalkozásod bemutatása. A Vállalkozás leírása részben bemutathatod magadat és vállalkozásodat az ügyfeleidnek. Írd le a motivációidat, céljaidat, terveidet - vagy bármit, amit fontosnak érzel. Mentsd el, majd ellenőrizd a Rólam menüben foglalási oldaladon, hogy minden rendben van-e. Ha szükséges, javítsd és frissítsd a tartalmat."},
      { type: "image", content: settings7 },
      { type: "text", content: "4. Közösségi média linkek megadása. Add meg a közösségi média profiljaid linkjeit (pl. Facebook, Instagram), amelyek megjelennek a foglalási oldaladon, valamint a visszaigazoló és emlékeztető emailekben is. Ezzel is növelheted az elérésedet és erősítheted az online jelenlétedet."},
      { type: "image", content: settings3 },
      { type: "text", content: "5. Számlázási adatok megadása. Számlázási adataidra azért van szükség, ha elégedett vagy a rendszerünkkel, és úgy döntesz, hogy hosszú távon is használod azt."},
      { type: "image", content: settings4 },
      { type: "text", content: "6. Egyedi domain és SEO beállítások. Van saját domain neved? Állítsd be, hogy a foglalási oldalad arra az URL-re mutasson. Ha nincs domained, az egyedi foglalási oldal URL segítségével teheted személyre szabottabbá az elérhetőségedet. Ne felejtsd el beállítani a SEO címet és leírást is, hogy a keresőmotorok könnyebben megtalálják az oldaladat."},
      { type: "image", content: url },
      { type: "image", content: settings5 },
      { type: "text", content: "7. Referenciaképek feltöltése.  Tölts fel legfeljebb 5 képet a legjobb munkáidról, ügyfélvisszajelzésekről vagy elismerésekről. Ezek vizuális hitelességet és vonzerőt kölcsönöznek a foglalási oldaladnak."},
      { type: "text", content: "Google Naptár szinkronizálása. Ha Google-fiókkal regisztráltál, szinkronizálhatod az időpontfoglalásokat a Google Naptáraddal, hogy mindig naprakész maradj, és ne maradj le semmiről."},
      { type: "image", content: settings6 },
      { type: "text", content: "Ha bármikor úgy döntesz, hogy nem használod tovább a rendszerünket, a fiókodat azonnal és maradéktalanul törölheted."},
      { type: "text", content: "Ne feledd: Egy egységes, jól átgondolt online megjelenés nemcsak az ügyfeleid szemében növeli a megbízhatóságot, hanem a keresőmotorok számára is érthetőbbé teszi vállalkozásodat!"},
    
    ]
  },

  {
    id: 3,
    title: "Hogyan védheted meg fiókodat az adathalász csalások ellen?",
    thumbnail: cyber_secutiry,
    slug: slugify("Hogyan védheted meg fiókodat az adathalász csalások ellen?"),
    content: [
      { type: "text", content: "Képzeld el, hogy egy nap belépsz a Facebook-fiókodba, és azt látod, hogy minden megváltozott: ismeretlen bejegyzések, furcsa üzenetek az ismerőseidnek, és tehetetlenül próbálod visszaszerezni az irányítást. Ez nem egy rémálom, hanem sajnos sokak valósága, akik egy pillanatnyi figyelmetlenség miatt a csalók áldozatává váltak. Számtalan posztot láttam már, amiben segítséget kérnek fiók feltörés miatt. Bizonygatják vigyáztak, van 2FA biztonságuk, és mégis valakinek sikerült bejutni a fiókjukba, nem tudják hogyan, hisz ők óvatosak voltak."},
      { type: "text", content: "Gondoltad volna, hogy akár egy ártatlannak tűnő üzenet vagy link is elég ahhoz, hogy a csalók hozzáférjenek valamelyik fiókodhoz? Az online világban a phishing, vagyis az adathalászat az egyik leggyakoribb és legveszélyesebb átverési módszer, amellyel szembesülhetsz."},
      { type: "text", content: "De ne aggódj, nem vagy tehetetlen! Ebben a cikkben adok néhány tippet:"},
      { type: "text", content: "   📌 Hogyan ismerheted fel az adathalász próbálkozásokat?"},
      { type: "text", content: "   📌 Milyen módszerekkel lopják el a jelszavadat és veszik át az irányítást?"},
      { type: "text", content: "   📌 Mit tehetsz, ha már megtörtént a baj?"},
      { type: "text", content: "   📌 Hogyan biztosíthatod, hogy a fiókod mindig védve legyen?"},
      { type: "header", content: "Hogyan ismerheted fel az adathalász próbálkozásokat?"},
      { type: "text", content: "Az adathalász e-mailek és üzenetek célja, hogy rávegyenek egy hamis linkre kattintásra, amelyen keresztül megpróbálják megszerezni a fiók adataidat. Az ilyen kísérleteket a következő jelek alapján ismerheted fel:"},
      { type: "text", content: "1. Furcsa vagy hivatalosnak tűnő, de gyanús e-mail-címek: Az adathalász üzenetek gyakran olyan címről érkeznek, amely hasonlít az eredeti szolgáltató (például Meta) címéhez, de egy apró eltérés található benne (pl. *@metasupport.com helyett *@meta-security-alert.net). Azonban van számtalan eset, amikor generált email címről érkezik az üzenet, amit sokan úgysem néznek meg, hisz az email kinézete elég hiteles, az embernek eszébe se jut megnézi."},
      { type: "text", content: "2. A csalók üzenetei sokszor tartalmaznak nyelvtani hibákat, furcsa megfogalmazásokat vagy rosszul formázott szöveget. Ennek oka lehet, hogy az üzenetet lefordították (például magyar nyelvre), de nem ellenőrizték, így értelmetlen üzenet keletkezett."},
      { type: "text", content: '3. Sürgős cselekvésre felszólító üzenetek: Ha az üzenetben azt írják, hogy "azonnal cselekedj, különben elveszted a hozzáférésed", érdemes óvatosnak lenni. A sürgetés a csalók egyik leggyakoribb eszköze.'},
      { type: "text", content: "4. Gyanús linkek: Ha az üzenetben található link egy ismeretlen vagy furcsa URL-re mutat (például ahelyett, hogy a facebook.com-ra vezetne, egy hosszú és bonyolult URL-t látsz), akkor nagy valószínűséggel adathalászatról van szó."},
      { type: "header", content: "Most lássunk néhány példát ezekre: 👇"},
      { type: "image", content: phisingMeta2},
      { type: "text", content: "❗️ Ugye, így első pillantásra még valódi is lehetne? Meta színek, hihető cím, ott a termékünk Facebook-on szereplő neve is. Ami intő jel volt már, az az, hogy Junk-ba, vagyis Spam-be érkezett. Nézzük csak meg ki küldte!"},
      { type: "image", content: phisingMeta1},
      { type: "text", content: "❗️ Mutatok még egy trükköt. Helyezd a kurzort a linkre vagy gombra. NEHOGY RÁKLIKKELJ❗️❗️❗️ Csak helyezd fölé és várj, meg fog jelenni egy kis felirat mellette, vagy az oldal bal alsó sarkában, hogy a link milyen URL-re mutat. Alább láthatod, mit fogsz látni Inbox esetén, Gmail és browser esetében bal alsó sarokban keresd."},
      { type: "image", content: phisingMetaLink1},
      { type: "text", content: "❗️ Ugye, ugye❗️ Milyen szép email cím? Na ezért kell mindig ellenőrizni ki küldte az emailt, és a link hova mutat. Tipp: Facebook esetében korrekt cím lehet advertise-noreply@support.facebook.com."},
      { type: "text", content: "❗️ Lássunk egy másik példát. Ezt az emailt nem sokkal a Facebook fiók létrehozása után kaptuk. Itt is nagyon hihető, ott a Meta címe, Meta színt használ, tudják a termék nevét."},
      { type: "image", content: phisingMeta4},
      { type: "text", content: "Lássuk itt is ki küldte 🔍" },
      { type: "image", content: phisingMeta3},
      { type: "text", content: "❗️ Harmadik példa, csak hogy szemléltessem, milyen kreatív megoldásaik vannak a csalóknak."},
      { type: "image", content: phisingMeta6},
      { type: "text", content: "❗️ Láthatólag semmi különös, hivatalosnak tűnő Facebook email. Nézzük meg mit kapunk annak a két módszernek a segítségével, amit az imént mutattam"},
      { type: "image", content: phisingMeta5},
      { type: "text", content: "❗️ Hopp de fura email cím❗️"},
      { type: "image", content: phisingMetaLink2},
      { type: "text", content: "❗️ Hopp de fura URL link❗️ Mindig legyünk résen, és soha nem szabad linkre kattintani, amíg nem vagyunk biztosak abban, hogy megbízható."},
      { type: "text", content: "Az alábbi üzenetet Instagram fiókon keresztül kaptuk. Láthatod, hogy a küldő, valamint a link is elég érdekes." },
      { type: "image", content: uzenet1 },
      { type: "text", content: 'Valamint hoztam három sms-t, amik szintén csalók próbálkozásai. Ilyen esetekben is mindig ellenőrizzük a számot vagy címet, ahonnan az üzenet érkezett. Ha például a feladó "Posta", de egy teljesen ismeretlen, nem magyar szám jelenik meg, az már gyanús lehet. Hasonlóképpen, ha a szöveg tele van helyesírási hibákkal, rosszul szerepel a feladó neve, vagy ha az üzenet túl általános, az is figyelmeztető jel lehet.' },
      { type: "image", content: uzenet2 },
      { type: "image", content: uzenet3 },
      { type: "image", content: uzenet4 },
      { type: "text", content: "Érdekes linkek nem igaz? Biztos számos hasonló üzenettel találkoztál már te is rendőrségtől, Netflixtől stb. Kedvencünk, mikor olyan szolgáltatótól jön üzenet, akinél sose volt fiókunk. Gyanús nem? :) Mindig résen kell lenni, és ha ezt a három dolgot mindig leellenőrzöd, sokban javítasz biztonságodon." },
      { type: "header", content: "Milyen módszerekkel lopják el a jelszavadat és veszik át az irányítást?"},
      { type: "text", content: "A csalók kreatív módszerekkel próbálnak hozzáférni az adataidhoz. Felsorolok párat:"},
      { type: "text", content: "1. Hamis bejelentkezési oldalak: Az e-mailben található link egy olyan oldalra vezethet, amely pontos másolata az eredeti Facebook/Istagram/Posta/Netflix bejelentkezési oldalnak. Ha itt megadod a jelszavadat, az azonnal a csalók kezébe kerül."},
      { type: "text", content: "2. Kártékony csatolmányok: Az e-mailben szereplő fájlok (pl. PDF vagy ZIP) rosszindulatú szoftvert tartalmazhatnak, amely a gépeden lévő adatokat gyűjti össze."},
      { type: "text", content: '3. Social engineering (társadalmi manipuláció): Egyes csalók azt is megpróbálhatják, hogy telefonon vagy üzenetben személyesen kérik el az adataidat, például "biztonsági ellenőrzésre" hivatkozva. Ez a jól ismert banktól hívtam, fura tranzakciót észleltünk, adja meg az adatait és tiltjuk. Na ilyen nincs! Ezekkel óvatosn.' },
      { type: "text", content: "4. Adatbázis-szivárgások: Ha egyszer már feltörték valamelyik régi fiókodat, a korábbi jelszavadat megpróbálhatják más platformokon is használni." },
      { type: "text", content: "5. Hirdetések manipulálása: Egyes adathalász kísérletek nemcsak e-mailben érkeznek, hanem hamis hirdetéseken keresztül, ahol rákattintva egy gyanús weboldalra kerülsz." },
      { type: "header", content: "Mit tehetsz, ha már megtörtént a baj?" },
      { type: "text", content: "Ha úgy érzed, hogy az online fiókod veszélybe került, vagy már hozzáférést szereztek hozzá, kövesd ezeket a lépéseket, hogy visszaállíthasd a biztonságot:" },
      { type: "text", content: "1. Változtasd meg az online fiókod jelszavát: Az első lépés mindig a jelszó módosítása. Ha nem tudsz belépni, használd az online fiók-helyreállítási lehetőségeit. A fiók helyreállítási funkciók segítenek visszaállítani a hozzáférést." },
      { type: "text", content: "2. Értesítsd a szolgáltatót: Minden online szolgáltatásnak van bejelentési rendszere az ilyen esetekre. Jelentsd az incidenset, és kérj segítséget a fiók visszaállításához. Alább belinkelem a Meta leírását, hogyan tudsz gyanús tevékenységet jelenteni." },
      { type: "linkText", content: "🔗 Meta Business Help link", link: "https://www.facebook.com/business/help/173435393294159?id=867336363714190" },
      { type: "text", content: "3. Ellenőrizd a kapcsolt eszközöket: A fiókod beállításai között keresd meg az aktív bejelentkezéseket, és léptess ki minden gyanús eszközt. Ezzel megakadályozhatod, hogy a csalók további hozzáférést szerezzenek." },
      { type: "text", content: "4. Kapcsold be a kétlépcsős hitelesítést: A kétlépcsős hitelesítés (2FA) egy erős biztonsági funkció, amely védelmet nyújt a fiókhoz való jogosulatlan hozzáférés ellen, még akkor is, ha valaki megszerezte a jelszavadat." },
      { type: "text", content: "5. Ellenőrizd az e-mail fiókodat: Ha a fiókodhoz kötött e-mail fiókod is veszélybe került, azonnal védd meg azt is. Állítsd vissza a jelszót és frissítsd a biztonsági beállításokat." },
      { type: "text", content: "6. Jelentsd az adathalász e-maileket: A legtöbb e-mail szolgáltató lehetővé teszi az adathalász e-mailek jelentését. Ezzel segíthetsz megakadályozni az ilyen támadásokat a jövőben, és blokkolhatod az adathalász e-maileket. Alább linkelem a Google adathalászat megelőzése és bejelentésére szolgáló linket." },
      { type: "linkText", content: "🔗 Google Support link", link: "https://support.google.com/websearch/answer/106318?hl=hu" },
      { type: "header", content: "Hogyan biztosíthatod, hogy a fiókod mindig védve legyen?" },
      { type: "text", content: "A legjobb módja a megelőzésnek, ha elővigyázatos vagy. Az alábbi tippek segítenek abban, hogy online fiókjaid biztonságban legyenek:" },
      { type: "text", content: '1. Használj egyedi, erős jelszót: Mindig válassz erős és egyedi jelszót. Kerüld a könnyen kitalálható jelszavakat, mint például a "123456" vagy a "password". Használj hosszú, kombinált jelszavakat, amelyek számokat, betűket és szimbólumokat is tartalmaznak.' },
      { type: "text", content: "2. Kapcsold be a kétlépcsős hitelesítést (2FA): A kétlépcsős hitelesítés extra biztonságot nyújt. Ez megakadályozza, hogy illetéktelen személyek hozzáférjenek a fiókodhoz, még ha meg is szerezték a jelszavadat." },
      { type: "text", content: '3. Figyeld a biztonsági értesítéseket: Az online platformok, mint például a közösségi média és e-mail szolgáltatók, értesítéseket küldenek, ha gyanús aktivitást észlelnek. Figyeld ezeket az értesítéseket, ellenőrizd ki küldte, hova mutat, majd ha már meggyőződtél a hitelességről, teljesítsd a benne foglaltakat.' },
      { type: "text", content: "4. Rendszeresen ellenőrizd a fiók beállításait: Győződj meg róla, hogy a fiókhoz tartozó e-mail címek, telefonszámok és biztonsági kérdések naprakészek és pontosak legyenek." },
      { type: "text", content: "5. Frissítsd a rendszered és a böngésződet: A régi operációs rendszerek és böngészők biztonsági rések miatt sebezhetőbbek lehetnek. Rendszeresen frissítsd őket a legújabb verziókra, hogy elkerüld a biztonsági problémákat." },
      { type: "text", content: "6. Kerüld a nyilvános Wi-Fi hálózatokat: Használj VPN-t (virtuális magánhálózatot), ha nyilvános Wi-Fi-t kell használnod." },
      { type: "header", content: "Összegzés:" },
      { type: "text", content: "Az adathalász próbálkozások gyakoriak, de kellő körültekintéssel és biztonsági beállításokkal megvédheted a fiókodat. A legfontosabb: " },
      { type: "text", content: " ❗️ soha ne add ki az adataidat gyanús forrásnak" },
      { type: "text", content: " ❗️ mindig ellenőrizd, hogy az e-mail valóban a hivatalos szolgáltatótól érkezett-e" },
      { type: "text", content: " ❗️ URL hova mutat" },
      { type: "text", content: "Ha ezekre odafigyelsz, megakadályozhatod, hogy a csalók sikerrel járjanak. 🛡️" },
      { type: "text", content: "Szerző: Wobble csapat" },
      ],
  },

  {
    id: 4,
    title: "Miért érdemes vállalkozni? Miért éri meg képességeinket pénzre váltani?",
    thumbnail: manage_business,
    slug: slugify("Miért érdemes vállalkozni? Miért éri meg képességeinket pénzre váltani?"),
    content: [
      { type: "text", content: "A vállalkozás világa nemcsak egy életmód, hanem egy lehetőség is, hogy saját magunkat építsük, értéket teremtsünk, és a tudásunkat pénzre váltsuk. Ha valaha is gondolkodtál azon, hogy saját vállalkozást indíts, most van itt az ideje, hogy megértsd, miért lehet ez a legjobb döntés az életedben. De mi rejlik a vállalkozás hátterében? Miért érdemes a saját képességeinket pénzre váltani, és miért érdemes vállalkozásba kezdeni a mai piacon? Tudd meg, hogy mi vár rád, és hogyan vágj bele!" },
      { type: "header", content: "1. Szabadság a saját időd felett - A vállalkozás igazi előnye" },
      { type: "text", content: "A vállalkozás egyik legnagyobb előnye a szabadság, amit a saját időd és helyed felett gyakorolhatsz. Nem vagy már kiszolgáltatva egy főnöknek vagy egy rigid munkarendnek. Ha vállalkozóvá válsz, lehetőséged nyílik arra, hogy rugalmasan alakítsd a napjaidat (azt vedd figyelembe, hogy nem minden szakmai terület engedi meg a teljes hely és idő szabadságának lehetőségét). Ez persze nem azt jelenti, hogy lustizol, amíg kedvet nem kapsz egy kis munkára, pont az ellenkezője igaz: rendkívül kemény munka és kitartás szükséges, főleg az első időszakban, amikor minden teher a te válladon nyugszik." },
      { type: "text", content: "Viszont az igazi szabadság abban rejlik, hogy saját magad dönthetsz arról, mikor és hol dolgozol. Ha úgy alakul, hogy egy délelőtt inkább ügyeket intézel, például elintézel néhány személyes dolgot vagy találkozót, megteheted. A munkaidő rugalmas, tehát a nap hátralévő részében, este vagy akár hétvégén is bepótolhatod a munkát. Ezen túl, a vállalkozás lehetőséget ad arra is, hogy bárhonnan dolgozhass, nem vagy az iroda vagy a munkahelyhez kötve. Ez a fajta szabadság időben és helyben is azt jelenti, hogy könnyebben tudod összhangba hozni a magánéletedet és a munkát, és a saját tempódban haladhatsz."},
      { type: "header", content: "2. A pénzkereseti lehetőség bővítése - Hogyan váltsd pénzre a tudásod?" },
      { type: "text", content: "Ha vállalkozol, akkor lehetőséged van arra, hogy a tudásodat és képességeidet pénzre váltsd. Ha rendelkezel egy olyan készséggel, amit mások értékelnek és hajlandók fizetni érte, akkor a vállalkozás segítségével ezt a tudást értékesítheted. Legyen szó tanácsadásról, programozásról, szövegírásról, vagy akár fizikai termékekről, a lehetőségek végtelenek. A saját vállalkozás a tudásod piacképessé tételét jelenti, és ha kitartóan dolgozol, akkor az anyagi eredmény sem fog elmaradni."},
      { type: "header", content: "3. A munka melletti vállalkozás - Hogyan kezdj neki anélkül, hogy mindent feladnál?"},
      { type: "text", content: "Nem mindenkinek adatik meg, hogy teljes munkaidőben vállalkozzon, különösen akkor, ha már egy stabil állásban dolgozik. Azonban a munka melletti vállalkozás egy remek lehetőség arra, hogy fokozatosan építsd fel saját vállalkozásodat anélkül, hogy teljesen lemondanál a biztos jövedelemről. A munka mellett kezdett vállalkozás előnye, hogy megtartja a pénzügyi stabilitást, miközben lehetőséget ad arra, hogy kipróbáld, hogyan működik az üzlet, és milyen piacra lépni. Egy munka melletti vállalkozás segíthet abban, hogy alacsony kockázattal és fokozatosan haladj előre. Emellett könnyen kialakíthatod a megfelelő időbeosztást, hiszen a munkahelyi feladataid mellett, a vállalkozásban való részvétel a szabadidődből vehet el időt. Persze nem lesz könnyű – kevesebb szabadidővel kell szembenézned, és gyakran hosszú órákat kell dolgoznod a munkahelyeden, majd a vállalkozásban is. De ha sikerül összhangba hozni a kettőt, és a vállalkozás beindul, jó jövedelemkiegészítő, és később dönthetsz arról, hogy teljesen elhagyod a munkahelyedet."},
      { type: "header", content:"4. A teljesen független vállalkozás - A végső szabadság?" },
      { type: "text", content:"Ha sikerült a munka melletti vállalkozásod felépíteni, elérheted azt a pillanatot, amikor már nem szükséges többé alkalmazotti munkát végezned. A teljesen független vállalkozás nemcsak a pénzügyi szabadságot adja meg, hanem a személyes életminőséget is. Az egyik legnagyobb előnye, hogy a munkát és a magánéletet te osztod be, és valódi szabadságot élvezhetsz a döntéseid felett. A teljes függetlenség eléréséhez azonban komoly előkészítés szükséges. Nagyon fontos, hogy a vállalkozásod stabil alapokra épüljön, és hogy a jövedelmed biztos forrást adjon a megélhetéshez. Emellett a döntéseid hatással lesznek a vállalkozás hosszú távú sikerére, ezért érdemes olyan irányt választani, amit valóban szenvedéllyel tudsz művelni. A független vállalkozás elérése nem könnyű, de ha elég kitartó vagy, és megfelelő stratégiát választasz, akkor az eredmény megéri az erőfeszítést." },
      { type: "header", content: "5. A nehézségek - Miért nem könnyű vállalkozni?"},
      { type: "text", content: "A vállalkozás nem egy egyszerű út, és ennek megértése elengedhetetlen. Kezdetben minden új, minden ismeretlen, és a vállalkozó gyakran egy személyben végzi el az összes feladatot: marketing, ügyfélszerzés, pénzügyi tervezés, jogi kérdések – mindent neked kell kezelni. A legfontosabb ebben az időben a folyamatos tanulás. Az első hónapok, akár évek, tele lehetnek kihívásokkal, amikor minden döntés és feladat a te kezedben összpontosul. Az eladásokat növelni kell, a költségeket optimalizálni, és ügyfeleket szerezni, mindezt úgy, hogy közben figyelsz a minőségre és a márkaépítésre is."},
      { type: "text", content: "Azonban az idő múlásával, amikor a vállalkozás növekszik, elérkezik az a pont, ahol már nem végezheted el mindent egyedül. Itt jön a kulcsfontosságú lépés: meg kell tanulnod kiszervezni a feladatokat a megfelelő szakembereknek, munkatársaknak. Az alkalmazottak vagy külső partnerek bevonása lehetővé teszi, hogy te a legfontosabb dolgokra összpontosíts, miközben mások elvégzik azokat a területeket, amelyekre nem te vagy a legalkalmasabb. A kezdeti nehézségek tehát, bár nagyon kemények, végül a fejlődés és a tanulás eszközeivé válhatnak, és segíthetnek abban, hogy a vállalkozásod hosszú távon sikeres és fenntartható legyen."},
      { type: "header", content: "6. A kudarcokból való tanulás - Miért lehet előny a nehézségekkel való szembenézés?"},
      { type: "text", content: "A vállalkozás világában nemcsak a siker, hanem a kudarc is fontos része a tanulásnak. A sikeres vállalkozók többsége előbb vagy utóbb szembesül valamilyen kudarcélménnyel. A kulcs az, hogy miként kezeled a nehéz helyzeteket. A kudarcok nem végállomások, hanem lépcsőfokok a siker felé. Ha az első próbálkozás nem hozza meg a kívánt eredményeket, az nem jelenti azt, hogy fel kell adnod. A vállalkozásba való belevágás az egyik legnagyobb lehetőség arra, hogy saját magadat fejleszd, hogy alkalmazkodj, tanulj, és új utakat találj. Mindezek a tapasztalatok az idő múlásával segítenek egy erősebb és sikeresebb vállalkozás felépítésében."},
      { type: "header", content: "7. A vállalkozás előnyei - Miért éri meg végül?"},
      { type: "text", content: "A legnagyobb előny, amit a vállalkozás nyújt, az az, hogy képes vagy irányítani a sorsodat. Ha sikerül megtalálni a megfelelő piacot, ügyféltábort és üzleti modellt, akkor a vállalkozás nemcsak anyagi sikerhez vezethet, hanem egy olyan életformához, ahol te magad alkothatod meg a szabályokat. A vállalkozás a pénzügyi szabadság kulcsa, hiszen ha helyesen építed fel, akkor a bevételek folyamatosan nőhetnek. A vállalkozás tehát nemcsak egy lehetőség, hanem egy kihívás is, amely arra ösztönöz, hogy kibővítsd saját határaidat, és olyan dolgokat érj el, amiket alkalmazottként valószínűleg nem tudnál. Bár nem mindenki számára való, azok, akik sikerrel átvészelik a nehézségeket, hatalmas jutalmakat élvezhetnek."},
      { type: "header", content: "8. A sikeres vállalkozás titkai: Hogyan vágj bele?"},
      { type: "text", content: "Ha úgy érzed, készen állsz a vállalkozás indítására, kezdj el dolgozni a legfontosabb alapokon. Készíts egy üzleti tervet, határozd meg a céljaidat és azokat a lépéseket, amelyek segítenek a megvalósításban. Kezdj el keresni azokat a lehetőségeket, amelyek a piacon betöltött rést kitöltik, és próbálj folyamatosan tanulni a vállalkozás különböző aspektusairól. Ne hagyd, hogy a félelem megakadályozzon abban, hogy belevágj!"},
      { type: "text", content: 'Túl sokat látod hogy "kezd el most!", "egyszerű manapság egy vállalkozás indítása!" mondatokat, de úgy érzed nem igaz és nem tudod merre? Elhiheted, én tudom hogy érzel. A Wobble-t azért hoztuk létre, hogy ezt az érzést csökkentsük, és minden induló egyéni-és kisvállalkozónak meglegyen az az kiinduló pont, ami külön-külön kezelve idő és pénz. Online jelenlét, időpontfoglaló, email, blog, ügyfélkezelés, és ami talán a legfontosabb, automatizálás. Kérdésed van, keress bátran! Segítek!'}
    ]
  },

  {
    id: 5,
    title: "Hogyan használjuk az AI-t vállalkozásunkban?",
    thumbnail: ai_header,
    slug: slugify("Hogyan használjuk az AI-t vállalkozásunkban?"),
    content: [
      { type: "header", content: "AI a vállalkozás segítésére" },
      { type: "text", content: "Gondolom te is folyton ezt hallod a neten, csoportokban, a boltban, buszon, de még a csapból is, hogy mesterséges intelligencia, AI, MI és hasonlók. Ahogy korábban is voltak nagy technológiai újítások, amik velünk is maradtak, ha tetszett másoknak, ha nem, úgy most is, és ez nem más, mint az AI. Lehet utálni, elveszi a munkánkat, megbízhatatlan, stb. De ha tetszik, ha nem, a mesterséges intelligencia jött, látott, és úgy néz ki, velünk marad még sokáig. Így hát nincs más választás, mint megszoksz, vagy megszöksz. Ha az utóbbit választod, a versenytársaid tuti beelőznek téged idővel, több ügyfelet szereznek, jobban hirdetnek, jobb ötleteik lesznek, mindezt olcsóbban. Megéri? Nekik igen. Neked? Nem hiszem. Így azt tudom tanácsolni, fordítsd inkább a javadra a mesterséges intelligencia által nyújtott lehetőségeket. Írok pár tippet, hogyan tudod hasznosítani a ChatGPT-t a vállalkozásodban." },
      { type: "text", content: "Ezek a tippek általunk tesztelt és bevált módszerek, ettől függetlenül egy komolyabb projekthez jobb inkább keresni egy jó és megbízható szakembert." },
      { type: "header", content: "ChatGPT" },
      { type: "text", content: "Valószínű erről már mindenki hallott. Felteszel neki egy kérdést, vagy írsz neki egy kérést, ő meg elég sokféle szempontból megvizsgálva válaszol/ kifejti. Minél több részlettel teszed fel a kérdést, annál részletesebben fogja kifejteni a válaszát. Érdemes próbálkozni többfajta kérdéssel, leírással. Pl. Hozz létre egy szöveget egy palánta ültetési akcióról, valószínű elég általános leírást fogsz kapni. Azonban ha megadod, hogy hozz létre egy Facebook hirdetést a Heaven oasis nevű kertészetnek, legyen figyelemfelhívó, kreatív, lelkes, használj emojikat, és a célközönség a kertészkedést kedvelő hobbi kertészek, na erre már sokkal specifikusabb szöveget fogsz kapni. Minél több részlet, annál részletesebb végeredmény." },
      { type: "text", content: " Mire lehet hasznos Neked?" },
      { type: "text", content: "📌 Szövegírás: Akkor jöhet jól, ha a foglalkozásod távol esik a frappáns marketing szövegírástól, mert valljuk be, nem érthetünk mindenhez, de elengedhetetlen egy jól megfogalmazott poszt ügyfeleidnek. Írd meg a szöveget, majd add be a ChatGPT-nek, hogy írja át humorosra, érdekesre, Facebook hirdetés jellegűre, használjon emojikat, vagy amit csak szeretnél. Minél több részlet, annál jobb eredmény. Ne feledd, a válasza nem mindig tökéletes, sőt, sokszor elsőre nem a megfelelő. Kérj újat, kérj még 5-öt, írd át kicsit az ötleted, pontosítsd, és generáltass újat. Ha egyik sem tetszik, hát ne használd. Ha viszont tetszik, igazíthatsz még rajta, ha úgy érzed, és már tudod is használni. Sok időt és pénzt tudsz ezzel spórolni, főleg az indulási időszakban, mikor a tőke még kevés." },
      { type: "text", content: '📌 Ötletelés: Amennyiben elakadtál egy dologban, inspiráció kellene, legyen az új poszt, történet, hirdetés, megjelenés, a ChatGPT mint egy asszisztens tud neked ötleteket adni. Írd meg neki, min akadtál el, mit szeretnél, min gondolkodsz, írd le, mire kell, mihez lenne, és kérj ötletet tőle. Nagyon sok inspirációt tud adni. Még ha nem is az általa írt ötlet lesz a befutó, de azzal, hogy leír szerinte számodra érdekes lehetőségeket, megmozgatja a fantáziád, elindul egy másfajta gondolkodás. "Legrosszabb" esetben még a megfelelő ötlettel is előáll számodra, amit tovább tudsz vinni. Érdemes tesztelni.' },
      { type: "text", content: "📌 Helyesírás ellenőrzés: Ha másra nem is, de helyesírás ellenőrzésre megéri használni. Nincs is annál kiábrándítóbb, mint mikor egy komoly hirdetés, poszt tele van hibával, amire persze mindenki ráugrik. Emberek vagyunk, mind hibázunk. De egy rövid poszt igazán ne tartalmazzon már hibát. Ha nem vagy biztos a helyesírásodban, kérd meg, hogy ellenőrizze le, de a tartalmon ne módosítson. Természetesen még egyszer olvasd át és ellenőrizd ezután is." },
      { type: "text", content: "📌 Kutatás, keresés: A munkádhoz szükséged van kimutatásra, elemzésre, meg tudod kérni, hogy ajánljon neked forrást, vagy oldalt, ahol utána tudsz nézni a keresett dolgoknak. Ahogy említettem, az asszisztensed tud lenni, kérdezz és választ ad. Persze semmit ne vegyél kézpénznek, mindent ellenőrizz, de többségében tud segíteni kiindulási pontokkal, sőt most már forrást is megjelöli, vagy ha nem, kérj forrást is, ahol le tudod ellenőrizni a tényeket." },
      { type: "text", content: "📌 Kép generálás: Most már van lehetőség képet is generáltatni ChatGPT-vel, azonban 3-4 kép a limit, ahogy láttam. Erre már vannak jobb AI lehetőségek, de indulásnak ez sem rossz, érdemes kipróbálni. Írd le, mit szeretnél, pl. egy vállalkozó nő, aki fáradt, de még próbál időpontot egyeztetni egy ügyféllel, aki nem veszi fel a telefont. Így sikerült."},
      { type: "image", content: ai1},
      { type: "text", content: "📌 Összefoglalók: Van egy nagyobb terjedelmű szöveg, amit összesítened kell, add be ChatGPT-be, és kérd meg, hogy foglalja össze neked a lényeget röviden. Persze érdemes olyan szöveget, ami bizalmas adatokat nem tartalmaz." },
      { type: "text", content: "Ezen kívül még jó pár dologra alkalmazható, de talán ezekkel a módszerekkel tudja leginkább megkönnyíteni, felgyorsítani a napi szintű repetatív munkát. Remek segítség lehet egy fodrász, kozmetikus, személyi edző, magántanár, fogorvos, tetováló művész, fotós, kutyakozmetikus vállalkozónak, akik saját vállalkozásukat kezdik el, vagy már irányítják azt, és nincs még lehetőség kiszervezni ezeket a napi szintű feladatokat. Hisz a megjelenés a legfontosabb a bizalomkeltés érdekében, ezért ez a fajta tudás csak a hasznára válik az embernek. Tudásba sose lehet eleget belefektetni, és azt a tudást senki sem veszi el."},
      { type: "header", content: "Copilot" },
      { type: "text", content: "A Copilot hasonlóan működik, mint a ChatGPT: leírod, hogy mit szeretnél, mire vagy kíváncsi, vagy mit szeretnéd, hogy megírjon helyetted, és megírja. Magyarul is használható, és ingyenesen, regisztráció nélkül is elérhető. Azonban ebben az esetben csak korlátozottan használható, és képeket például csak bejelentkezés után lehet vele készíteni."},
      { type: "text", content: "Mire tudod használni a Copilotot?" },
      { type: "text", content: "📌 Szövegírás: Akárcsak a ChatGPT-nél, itt is elég leírnod a kérdésed, vagy megkérni, hogy fejtse ki az adott témát. A generált szöveg forrását itt is megjeleníti. Személyes tapasztalatom szerint a szövegek kevésbé terjedelmesek és kifejtőek voltak, mint a ChatGPT esetében, szolidabban fogalmazott, és néha előfordultak nem túl magyaros mondatok. Ezért mindenképpen érdemes átolvasni a végeredményt." },
      { type: "text", content: "📌 Ötletelés: A Copilot nagyon jó ötleteléshez. Leírod, milyen témában lenne szükséged friss gondolatokra, és már sorolja is az elképzeléseit. Akár marketingkampány-terveket is kérhetsz tőle. Az eredményeket érdemes átnézni és ellenőrizni, de a tesztelés során egész használható ötleteket adott." },
      { type: "text", content: "📌 Helyesírás ellenőrzése: A helyesírási hibákat pontosan javítja, a szövegen nem változtat, ha nem kéred. A javítások eddig jónak bizonyultak." },
      { type: "text", content: "📌 Keresés és kutatás: Friss és naprakész adatokat nyújt, a legújabb információkat is megtalálja. A forrásokat kérés nélkül is megjelöli." },
      { type: "text", content: "📌 Kép generálása: Kipróbáltam vele, és generáltattam egy jókedvű, dolgozó vállalkozónőről szóló képet. Az eredmény alább látható." },
      { type: "image", content: ai2 },
      { type: "text", content: "📌 Összefoglaló írása: Teljes mértékben alkalmas erre. Megadod neki a szöveget, és gyorsan elkészíti az összegzést." },
      { type: "text", content: "Összegzés" },
      { type: "text", content: "Összességében a Copilot és a ChatGPT nagyon hasonló funkciókat kínál. Ugyanakkor számomra a ChatGPT gördülékenyebb és természetesebb beszélgetési élményt nyújtott. Érdemes kipróbálni mindkettőt, hogy lásd, melyik nyújtja a legjobb segítséget a különböző feladatokhoz." },
      { type: "text", content: "Próbáld ki, használd okosan, mindig ellenőrizd, de észre fogod venni, mennyivel gyorsabban végére érsz a teendőidnek, és új ötletek is jöhetnek a ChatGPT vagy Copilot segítségével. Hagyd le versenytársaidat, szerezz előnyt magadnak." },
      { type: "text", content: "Szerző: Anna a Wobble csapatból" }
    ]
  },

  {
    id: 6,
    title: "Hogyan optimalizáld hatékonyabban a vállalkozásodat?",
    thumbnail: optimiz_header,
    slug: slugify("Hogyan optimalizáld hatékonyabban a vállalkozásodat?"),
    content: [
      { type: "text", content: "A kisvállalkozók és egyéni vállalkozók számára a napi teendők sokasága gyakran kaotikussá válhat. Az ügyfelek kezelése, időpontok egyeztetése, hívások fogadása és a folyamatos fejlődésre való törekvés között könnyen elveszhet a fókusz. Ebben a blogcikkben megmutatom, hogyan tudod optimalizálni a vállalkozásodat, hogy időt spórolj, növeld a hatékonyságodat, és jobb ügyfélélményt biztosíts." },
      { type: "header", content: "1. Automatizáció bevezetése" },
      { type: "text", content: "A vállalkozók egyik legnagyobb időrabló tevékenysége az adminisztráció, különösen az időpontok kezelése. Sokszor telefonos visszahívással vagy hosszú e-mailekkel, üzenetváltásokkal próbálják megszervezni az időpontokat. Közben ott az aktuális ügyfél, aki várja, hogy vele foglalkozzanak, vagy mire sikerül egy visszahívás, már késő: az ügyfél mást választott." },
      { type: "text", content: "Megoldás: Automatizált időpontfoglaló rendszerek." },
      { type: "text", content: "Egy ilyen rendszer:" },
      { type: "text", content: "     📌 Lehetővé teszi, hogy ügyfeleid saját maguk válasszanak időpontot," },
      { type: "text", content: "     📌 Automatikusan visszaigazolja a foglalást," },
      { type: "text", content: "     📌 Emlékeztetőket küld, hogy elkerüld a lemondásokat vagy a meg nem jelenéseket." },
      { type: "text", content: "Példa: Egy masszőr heti 5 órát spórolt meg azzal, hogy bevezette az automatizált időpontfoglaló rendszert, így több időt tudott a szolgáltatás minőségének fejlesztésére fordítani." },
      { type: "text", content: "Számos rendszer áll rendelkezésre ma már a piacon: olcsóbb, drágább, integrálható, teljes rendszer vagy személyre szabható megoldások is elérhetők. Ezek könnyen megtanulhatók, kezelhetők, és rengeteg időt spórolhatnak meg a vállalkozásod számára." },
      { type: "image", content: optimiz1},
      { type: "header", content: "2. Erősítsd digitális jelenléted" },
      { type: "text", content: "Az online jelenlét ma már nem luxus, hanem alapkövetelmény. A potenciális ügyfelek gyorsan, egyszerűen akarnak információt találni rólad. Senki nem fog végiggörgetni egy egész Facebook-oldalt, hogy megtalálja, mennyibe kerül egy hajvágás. Ha nincs ott az információ, viszlát. Nem is beszélve arról, hogy az újabb generáció inkább online informálódik, mielőtt szolgáltatót választ." },
      { type: "text", content: "Tippek:" },
      { type: "text", content: "     📌 Hozz létre egy átlátható, könnyen navigálható weboldalt." },
      { type: "text", content: "     📌 Használj közösségi médiát ügyfeleid elérésére." },
      { type: "text", content: "     📌 Integráld az időpontfoglaló rendszeredet a weboldaladra, hogy az ügyfelek azonnal tudjanak foglalni." },
      { type: "text", content: "Számos szoftver létezik, amivel gyorsan és egyszerűen létrehozhatsz egy landing oldalt. Egy egyszerű Wix vagy WordPress oldal ma már pillanatok alatt elkészíthető, ha kicsit utána nézel, vagy megkérsz valakit, hogy készítse el neked. A Wobble pont erre nyújt megoldást: nem szükséges fejlesztened, vagy drágán készíttetned oldalt, csak a személyre szabott beállításokat kell megadnod, és már kész is a saját online megjelenésed." },
      { type: "image", content: optimiz2},
      { type: "header", content: "3. Ügyfélélmény javítása" },
      { type: "text", content: "Az ügyfeleid elégedettsége közvetlenül befolyásolja vállalkozásod sikerét. Egy gördülékeny és gyors foglalási folyamat sokkal professzionálisabbá teszi a működésed." },
      { type: "text", content: "Példák az ügyfélélmény javítására:" },
      { type: "text", content: "     📌 Ahelyett, hogy napokig várnának a visszahívásodra, az ügyfelek azonnal le tudják foglalni az időpontjukat a rendszerben." },
      { type: "text", content: "     📌 Az automatikus emlékeztetők segítenek elkerülni a meg nem jelenéseket." },
      { type: "text", content: "     📌 Szolgáltatásod leírása és árai szépen összeszedve megjelennek az oldaladon." },
      { type: "header", content: "4. Használd az adatokat a fejlődéshez" },
      { type: "text", content: "Az adatok értékes információkat adhatnak arról, hogy mely szolgáltatásaid népszerűek, mikor foglalnak legtöbben, és milyen preferenciáik vannak az ügyfeleidnek." },
      { type: "text", content: "Mit mérj?" },
      { type: "text", content: "     📌 Hány ügyfél foglal időpontot hetente." },
      { type: "text", content: "     📌 Melyik időszakok a legforgalmasabbak." },
      { type: "text", content: "     📌 Mennyi a lemondások aránya, és hogyan tudod csökkenteni azokat." },
      { type: "text", content: "Az ilyen adatok alapján jobban tudod optimalizálni az időbeosztásodat, és még jobb élményt kínálhatsz ügyfeleidnek." },
      { type: "header", content: "5. Fókuszálj az időgazdálkodásra" },
      { type: "text", content: "Ha a napi rutinodban automatizálod az adminisztratív feladatokat, több időd marad arra, hogy a vállalkozásod fejlődésére koncentrálj." },
      { type: "text", content: "Példa: Egy fodrász napi közel egy órát tud megtakarítani azzal, hogy a foglalások és emlékeztetők kézi kezelését kiváltja egy egyszerű időpontfoglaló rendszerrel. Az így felszabadult időt új szolgáltatások kidolgozására, valamint social media tartalom készítésére fordíthatja." },
      { type: "image", content: optimiz3},
      { type: "text", content: "Indulj el még ma az optimalizáció útján!" },
      { type: "text", content: "A vállalkozásod optimalizálása nemcsak időt spórol neked, hanem ügyfeleid elégedettségét is növeli. Ha szeretnél egyszerű és gyors megoldást találni az időpontok kezelésére, online megjelenésre és ügyfélkezelésre, próbáld ki a Wobble időpontfoglaló rendszerét, és tapasztald meg a különbséget!" },
      { type: "text", content: "Kérdésed van?" },
      { type: "text", content: "Vedd fel velünk a kapcsolatot, és segítünk megtalálni a számodra legjobb megoldást! Keresd Annát 😊" },
      { type: "text", content: "Szerző: Anna a Wobble csapatból" }
    
    ]
  },

  {
    id: 7,
    title: "Online jelenlét",
    thumbnail: online_jelenlet,
    slug: slugify("Online jelenlét"),
    content: [
      { type: "header", content: "Az online időpontfoglalás és az online jelenlét egyre fontosabb szerepe" },
      { type: "text", content: "Az elmúlt években az emberek életvitele jelentősen megváltozott. A technológia fejlődése, különösen az internet és az okoseszközök elterjedése, alapjaiban formálta át a mindennapi szokásainkat, beleértve azt is, hogyan intézzük ügyeinket. Egyre több ember választja az online megoldásokat, amikor időpontot kell foglalnia, legyen szó orvosi vizsgálatról, fodrászatról vagy bármilyen szolgáltatás igénybevételéről." },
      { type: "header", content: "Miért preferálják az emberek az online időpontfoglalást?" },
      { type: "text", content: "Az egyik legfontosabb tényező az egyszerűség és a kényelem. Az online időpontfoglaló rendszerek lehetővé teszik, hogy a felhasználók bármikor és bárhonnan foglaljanak időpontot. Nincs többé szükség arra, hogy munkaidőn belül telefonáljunk, vagy személyesen intézkedjünk. A nap bármely szakaszában, akár hétvégén is elérhetőek ezek a rendszerek, így a felhasználók saját ütemük szerint szervezhetik az életüket. Egy 2021-es kutatás szerint a felhasználók 67%-a inkább online szeret időpontot foglalni, mint telefonon keresztül, mivel az gyorsabb és rugalmasabb megoldást nyújt . Ezen túlmenően a vállalkozások is profitálnak belőle, mivel az automatikus rendszer csökkenti az adminisztrációs terheket és kevesebb hibalehetőséget hagy a foglalások során." },
      { type: "header", content: "Az online jelenlét jelentősége" },
      { type: "text", content: "Az online időpontfoglalás mellett egy másik elengedhetetlen tényező a vállalkozások sikerében az online jelenlét. Egy professzionális weboldal vagy egy jól karbantartott közösségi média oldal megbízhatóságot sugall, és lehetőséget ad arra, hogy az ügyfelek könnyedén találjanak rá a szolgáltatásokra. Az emberek ma már először az interneten keresik fel a vállalkozásokat, mielőtt döntenének, hogy hol vegyék igénybe a szolgáltatásokat. Egy tanulmány szerint a vásárlók 88%-a utánanéz egy vállalkozásnak online, mielőtt döntést hozna . Ez azt mutatja, hogy a webes jelenlét és a digitális reputáció alapvető része lett annak, hogy egy vállalkozás sikeresen működjön. Ha egy cégnek nincs weboldala vagy online időpontfoglaló rendszere, az ügyfelek könnyen átvándorolhatnak a versenytársakhoz, akik ezeket a szolgáltatásokat kínálják." },
      { type: "header", content: "Miért érdemes befektetni az online rendszerekbe?" },
      { type: "text", content: "Az online időpontfoglaló rendszerek nemcsak az ügyfelek kényelmét szolgálják, hanem hatékonyabbá teszik a vállalkozás működését is. Egy jól működő rendszer automatikusan kezeli a foglalásokat, csökkenti az adminisztrációs feladatokat és minimalizálja a hibalehetőségeket. Ráadásul növeli a bizalmat is azáltal, hogy az ügyfelek láthatják az elérhető időpontokat, és azonnal tudnak foglalni anélkül, hogy sorban kellene állniuk vagy várniuk."},
      { type: "text", content: "Összegzésül, a mai világban elengedhetetlen, hogy a vállalkozások lépést tartsanak az online trendekkel, hiszen az ügyfelek egyre inkább elvárják a modern, digitális megoldásokat. Egy jól felépített online jelenlét, beleértve az online időpontfoglalási lehetőséget, nemcsak versenyelőnyt jelenthet, de alapvető feltétele annak, hogy a vállalkozás hosszú távon is sikeres maradjon."},
      { type: "text", content: 'Források: Think with Google: "How people book appointments in 2021", Statista: "Online consumer behavior trends, 2021"'}
    ]
  },

];
