import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { lightColor } from "./LandingPage";
import mockup_timeslot from "../../assets/images/mockup_timeslot.png";
import mockup_blog from "../../assets/images/mockup_blog.png";
import mockup_domain from "../../assets/images/mockup_cutsom_domain.png";
import mockup_dashboard from "../../assets/images/mockup_dashboard.png";
import mockup_email from "../../assets/images/mockup_email.png";
import mockup_info from "../../assets/images/mockup_info.png";
import SwipeableImage from "./SwipeableImage";
import { Helmet } from "react-helmet";

const FeatureSection = () => {
  return (
    <>
      <Helmet>
        <title>Wobble - Időpontfoglaló rendszer kisvállalkozásoknak</title>
        <meta
          name="description"
          content="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        />
        <meta
          name="keywords"
          content="időpontfoglaló, kisvállalkozás, online foglalás, egyszerű időpontfoglaló, foglalási rendszer"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph meta tag-ek */}
        <meta
          property="og:title"
          content="Wobble - Időpontfoglaló rendszer kisvállalkozásoknak"
        />
        <meta
          property="og:description"
          content="Egyszerű és gyors online időpontfoglaló rendszer egyéni- és kisvállalkozásoknak."
        />
        <meta property="og:url" content="https://wobble.hu/" />
        <meta
          property="og:image"
          content="https://wobble.hu/assets/wobble-blog.png"
        />
        <meta
          property="og:image"
          content="https://wobble.hu/assets/wobble-info-oldal.png"
        />
        <meta
          property="og:image"
          content="https://wobble.hu/assets/wobble-fooldal.png"
        />
        <meta
          property="og:image"
          content="https://wobble.hu/assets/google-szinkronizacio.png"
        />
        <meta
          property="og:image"
          content="https://wobble.hu/assets/google-naptar-szinkronizacio.png"
        />
        <meta
          property="og:image"
          content="https://wobble.hu/assets/wobble-email-kuldes.png"
        />
        <meta
          property="og:image"
          content="https://wobble.hu/assets/sajat-domain.png"
        />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Flex
        direction="column"
        align="center"
        justify="center"
        bg={lightColor}
        color="white"
        textAlign="center"
        p={8}
      >
        <Flex direction="column" align="center" mt={{ base: 12, sm: 150 }}>
          <Text fontSize="4xl" fontWeight="bold" color="white" mb={4} as="h1">
            Funkciók, amik elmaradhatatlanok számodra
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align="center"
            justify="center"
            maxW="7xl"
            mx="auto"
          >
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bg="white"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_timeslot}
                alt="Időpontfoglaló rendszer felület, gyors és egyszerű foglalások"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Időpontfoglaló
              </Text>
              <Text mt={2} as="h2" color="black">
                A Wobble időpontfoglaló rendszere segít elkerülni a telefonos
                foglalásokat és várakozásokat. Könnyen használható felület, hogy
                a foglalás azonnal megtörténhessen
              </Text>
            </Box>
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bg="white"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_info}
                alt="Egyéni megjelenés, vállalkozásod saját stílusában"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Saját megjelenés
              </Text>
              <Text mt={2} as="h2" color="black">
                Vállalkozásod egyedi megjelenését a Wobble biztosítja, beleértve
                a blogposztokat, szolgáltatásokat, értékeléseket és még sok más
                funkciót, amit egy személyre szabott weboldalon is megtalálsz
              </Text>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align="center"
            justify="center"
            maxW="7xl"
            mx="auto"
            mt={8}
          >
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bg="white"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_dashboard}
                alt="Admin felület, minden eszközzel, amire szükséged van"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Admin felület
              </Text>
              <Text mt={2} as="h2" color="black">
                A Wobble admin felülete lehetővé teszi, hogy egy helyen kezeld
                az összes szolgáltatásodat és ügyfeledet, miközben naprakészen
                tartod az eseményeidet
              </Text>
            </Box>
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bg="white"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_blog}
                alt="Írj blog posztot, oszd meg ügyfeleiddel"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Blog posztok
              </Text>
              <Text mt={2} as="h2" color="black">
                Oszd meg mi történik vállalkozásodban, készíst posztot egy
                napodról, új szolgáltatásodról, sikereidről
              </Text>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align="center"
            justify="center"
            maxW="7xl"
            mx="auto"
            mt={8}
          >
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bg="white"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_domain}
                alt="Saját domain"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Saját domain, vagy egyedi URL
              </Text>
              <Text mt={2} as="h2" color="black">
                Használd a saját domain neved, vagy használj egyedi url link
                megnevezést, az ingyenes SSL tanúsítványt mi adjuk
              </Text>
            </Box>
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bg="white"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <Image
                src={mockup_email}
                alt="Email marketing"
                loading="lazy"
                w={{ base: "100%", md: "100%" }}
                mx="auto"
                mb={4}
              />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Marketing email
              </Text>
              <Text mt={2} as="h2" color="black">
                Küldj azonnali, vagy időzített emailt ügyfeleidnek, tájékoztasd
                klienseidet új szolgáltatáodról vagy kedvezményekről
              </Text>
            </Box>
          </Stack>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={8}
            align="center"
            justify="center"
            maxW="7xl"
            mx="auto"
            mt={8}
          >
            <Box
              flex="1"
              p={4}
              textAlign="center"
              m={4}
              boxShadow="2xl"
              rounded={20}
              bg="white"
              color="white"
              transition="all 0.3s ease"
              _hover={{
                transform: "scale(1.05)",
                zIndex: 1,
                color: "black",
              }}
            >
              <SwipeableImage />
              <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
                Google Naptár szinkronizálási lehetőséggel
              </Text>
              <Text mt={2} as="h2" color="black">
                Szinkronizáld a Wobble foglalásaidat a Google naptáraddal, hogy
                könnyedén nyomon követhesd a találkozókat és eseményeket
              </Text>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};

export default FeatureSection;
