import { Helmet } from "react-helmet";

const SEOHelmet = () => {
  return (
    <Helmet>
      {/* Alap meta adatok */}
      <title>Wobble - Időpontfoglaló rendszer vállalkozásoknak</title>
      <meta
        name="description"
        content="Egyszerű időpontfoglaló rendszer, amely megkönnyíti vállalkozásod működését. Kevesebb lemondás, több szabadidő. Próbáld ki 14 napig ingyen!"
      />
      <meta
        name="keywords"
        content="időpontfoglaló, időpontfoglalás online, online naptár, vállalkozásoknak, ügyfél kezelés"
      />
      <link rel="canonical" href="https://wobble.hu/" />

      {/* Open Graph meta adatok a közösségi médiához */}
      <meta property="og:title" content="Wobble - Időpontfoglaló rendszer vállalkozásoknak" />
      <meta
        property="og:description"
        content="Spórolj időt és növeld bevételed! A Wobble segít egyszerűsíteni a vállalkozásod működését. Próbáld ki most 14 napig ingyen."
      />
      <meta property="og:image" content="https://wobble.hu/assets/wobble_idopontfoglalo.png" />
      <meta property="og:url" content="https://wobble.hu/" />
      <meta property="og:type" content="website" />

      {/* Mobilbarát meta tag */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Strukturált adatok (Schema.org) */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          name: "Wobble",
          url: "https://wobble.hu/",
          description: "Egyszerű időpontfoglaló rendszer, amely megkönnyíti vállalkozásod működését.",
          applicationCategory: "BusinessApplication",
          image: "https://wobble.hu/assets/wobble_idopontfoglalo.png",
        })}
      </script>
    </Helmet>
  );
};

export default SEOHelmet;
