export const steps_image2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinecap="round"
    strokeMiterlimit="10"
    clipRule="evenodd"
    viewBox="0 0 489 477"
  >
    <g transform="translate(-6.636 -23.125)">
      <g>
        <path
          fill="#E3F2FF"
          fillRule="nonzero"
          d="M0 239.001c-13.94 30.119-41.03 51.989-70.771 64.189-29.74 12.201-62.11 15.581-94.04 16.161-2.009.04-4.019.059-6.03.08-42.169.269-84.93-4.63-124.019-20.731a226.296 226.296 0 01-10.011-4.399c-43.569-20.481-81.589-57.59-96.31-105.191-6.139-19.849-8.139-40.95-7.199-61.79 1.089-24 6.359-47.75 15.19-69.909 14.879-37.31 40.149-76.21 75.219-94.4 84.321-43.71 164.851-12.33 195.471 19.65 32.92 34.38 45.63 90.43 77.699 125.69 11.66 12.809 26.591 22.17 37.271 35.889 20.189 25.931 21.469 64.651 7.53 94.761"
          transform="translate(454.69 150.899)"
        ></path>
        <path
          fill="#E3F2FF"
          fillRule="nonzero"
          d="M-.353-6.668l.1 6.697c0 .065-.099.054-.1-.01l-.1-6.697c0-.065.098-.053.1.01"
          transform="scale(-1 1) rotate(-6.053 4013.54 3840.704)"
        ></path>
        <clipPath id="_clip1">
          <path
            d="M136.72 113.91C101.65 132.1 76.38 171 61.5 208.31c-8.83 22.16-14.1 45.91-15.19 69.909-.94 20.841 1.06 41.941 7.2 61.791 14.72 47.601 52.74 84.709 96.309 105.19a228.02 228.02 0 0010.011 4.4c39.09 16.1 81.851 21 124.019 20.73 2.012-.02 4.021-.04 6.031-.08 31.929-.58 64.3-3.96 94.04-16.16 29.74-12.2 56.83-34.07 70.771-64.19 13.939-30.11 12.658-68.83-7.531-94.761-10.68-13.719-25.61-23.079-37.27-35.889-32.07-35.26-44.78-91.31-77.7-125.689-19.82-20.701-60.537-41.149-109.671-41.146-26.774.002-56.058 6.078-85.799 21.495z"
            clipRule="nonzero"
          ></path>
        </clipPath>
        <g clipPath="url(#_clip1)">
          <path
            fill="#C9E2F4"
            fillRule="nonzero"
            d="M0 3.62c-1.827-14.613-5.246-29.597-14.245-41.254-5.437-7.043-8.794-10.18-15.652-19.009-5.937-7.643-11.905-11.492-20.09-23.306 5.731 19.06 11.517 38.501 11.153 58.401-.191 10.443-2.082 20.855-1.437 31.279 1.496 24.146 16.745 45.889 36.267 60.174C6.006 77.229 17.63 83.04 30.021 83.569c-8.176-.348-18.411-23.804-21.255-30.183C1.91 38.013 2.053 20.047 0 3.62"
            transform="translate(378.061 374.828)"
          ></path>
          <path
            fill="#E3F2FF"
            fillRule="nonzero"
            d="M0-123.072c6.427 12.453 10.984 25.74 13.318 39.567 2.203 13.041 1.86 26.598 4.706 39.506 3.842 17.42 16.043 38.117 33 45.372.833.357 1.428-.876.718-1.382-16.416-11.703-29.43-28.598-32.164-49.098-1.747-13.097-2.142-26.353-4.903-39.308-2.644-12.409-7.091-24.25-13.113-35.41-.527-.977-2.077-.245-1.562.753"
            transform="translate(340.859 449.396)"
          ></path>
          <path
            fill="#C9E2F4"
            fillRule="nonzero"
            d="M0 42.462c.175-1.015.483-2.069 1.239-2.769 1.71-1.583 4.484-.521 6.758-1.03 2.892-.645 4.587-3.941 4.324-6.893-.262-2.952-2.01-5.556-3.937-7.807C4.632 19.582.022 16.002-3.759 11.647c-3.781-4.355-6.787-9.86-6.33-15.609 4.923-.751 9.97-.68 14.869.211C6.418-12.6-.864-20.226-5.826-27.733a52.723 52.723 0 01-8.602-25.916c-.142-2.516.087-5.438 2.132-6.91 1.381-.996 3.259-1.024 4.906-.595C4.493-58.06 4.451-37.133 16.539-34.978c-.756-2.35 2.231-4.578 4.61-3.924 2.38.653 3.857 3.062 4.536 5.435 1.82 6.359-.396 13.065-1.605 19.568-1.209 6.502-.945 14.26 4.235 18.373 2.27 1.801 5.246 2.665 7.223 4.783 3.953 4.236 2.139 11.044-.053 16.408-2.19 5.364-4.412 11.904-.943 16.547 2.303 3.082 6.461 4.22 9.056 7.061 5.638 6.17 1.319 15.784-.246 23.995a33.092 33.092 0 001.669 18.117c4.251 10.89-2.664 12.66-8.8 4.15-3.243-4.497-5.685-9.525-9.174-13.844C18.014 70.512-2.947 59.566 0 42.462"
            transform="translate(297.834 398.659)"
          ></path>
          <path
            fill="#E3F2FF"
            fillRule="nonzero"
            d="M0-135.782c6.357 10.355 12.506 20.653 16.484 32.21 4.139 12.023 5.066 24.457 6.487 36.998 1.318 11.635 3.986 23.031 7.541 34.178 1.918 6.011 4.082 11.942 6.397 17.811 2.055 5.21 3.975 10.913 6.97 15.657.392.621 1.395.229 1.199-.505-1.596-5.982-4.606-11.694-6.849-17.466-2.284-5.882-4.409-11.827-6.28-17.854-3.919-12.619-6.276-25.343-7.599-38.479-1.15-11.41-2.724-22.534-6.794-33.324a131.355 131.355 0 00-16.119-30.066c-.614-.853-2.008-.091-1.437.84"
            transform="translate(294.824 493.301)"
          ></path>
          <path
            fill="#C9E2F4"
            fillRule="nonzero"
            d="M0-17.27c-5.865-.62-11.979-1.945-17.514.09-6.928 2.547-10.973 9.581-14.443 16.097-1.876 3.523-4.163 8.684-5.913 14.091.278-7.18.105-14.387-.246-21.559-.552-11.293-1.556-22.792-1.786-34.238 5.313-2.222 16.374-5.636 21.168-6.978 4.098-1.147 8.249-2.316 11.921-4.468 3.763-2.206 6.869-5.355 9.927-8.467 5.624-5.721 11.554-12.054 12.517-20.019-4.98 2.785-10.131 5.627-15.802 6.263-3.175.357-6.384.003-9.576.127-7.96.31-15.758 3.726-21.383 9.367-2.365 2.361-6.614 14.153-8.817 20.597-.071-14.837 1.38-29.537 7.053-43.528.492-1.215-1.416-1.797-1.924-.619-10.613 24.604-8.004 51.361-6.692 77.407-3.75-9.23-10.86-20.502-15.248-26.774-.922-1.318-1.925-2.658-3.348-3.408-1.845-.972-4.054-.792-6.137-.679a69.761 69.761 0 01-31.2-5.554c3.936 8.971 8.768 17.712 15.672 24.663 6.903 6.95 16.098 12.002 25.886 12.406 4.656.192 10.357 2.338 14.651 5.395.463 11.707.446 23.483-1.419 35.068-2.444 15.172-8.6 28.996-13.703 43.358-.761 2.141 2.417 3.054 3.297 1.061 5.9-13.361 10.943-27.649 13.452-42.063.402-2.311.705-4.631.966-6.955 7.032-4.714 14.349-10.155 22.461-13.077C-.275 4.606 13.966-5.66 24.428-18.938A71.056 71.056 0 010-17.27"
            transform="translate(163.463 379.806)"
          ></path>
          <path
            fill="#C9E2F4"
            fillRule="nonzero"
            d="M0-35.101c-4.84 1.657-10.134 2.853-13.743 6.479-4.517 4.538-5.124 11.55-5.47 17.945-.186 3.458-.097 8.353.506 13.247-2.41-5.75-5.183-11.355-8.083-16.867-4.565-8.68-9.563-17.358-13.932-26.276 3.366-3.692 10.817-10.424 14.096-13.234 2.805-2.401 5.642-4.839 7.741-7.875 2.154-3.112 3.445-6.726 4.711-10.292 2.33-6.559 4.677-13.709 2.521-20.326-2.899 4.012-5.91 8.133-10.138 10.709-2.366 1.441-5.021 2.338-7.485 3.602-6.148 3.157-11.032 8.697-13.392 15.192-.995 2.722-.023 13.551.602 19.426-5.485-11.644-9.722-23.737-10.379-36.817-.058-1.136-1.772-.895-1.74.217.656 23.235 12.497 43.326 23.059 63.332-6.327-5.888-16.043-12.151-21.789-15.48-1.208-.699-2.487-1.385-3.881-1.455-1.807-.09-3.478.861-5.075 1.711a60.52 60.52 0 01-26.573 7.048c6.379 5.615 13.378 10.723 21.35 13.664 7.973 2.941 17.054 3.55 24.901.286 3.732-1.552 9.002-1.95 13.497-1.117 4.647 9.038 8.943 18.307 11.715 28.102 3.629 12.828 3.845 25.954 5.085 39.116.186 1.963 3.02 1.518 2.983-.371-.249-12.668-1.51-25.752-4.811-38.007-.529-1.964-1.139-3.9-1.785-5.824C-11.703 4.753-7.937-2.203-2.626-7.469 7.788-17.793 15.232-31.08 18.603-45.351A61.639 61.639 0 010-35.101"
            transform="translate(458.389 409.393)"
          ></path>
        </g>
        <path
          fill="#B8D7F4"
          fillRule="nonzero"
          d="M-61.569-61.569c-34.004 0-61.569 27.565-61.569 61.569 0 34.004 27.565 61.569 61.569 61.569C-27.565 61.569 0 34.004 0 0c0-34.004-27.565-61.569-61.569-61.569"
          transform="matrix(0 -1 -1 0 76.324 183.843)"
        ></path>
        <g transform="matrix(-1 0 0 1 227.247 -50.922)">
          <clipPath id="_clip2">
            <path
              d="M89.354 296.334c0 34.004 27.565 61.569 61.569 61.569 34.004 0 61.569-27.565 61.569-61.569 0-34.004-27.565-61.569-61.569-61.569-34.004 0-61.569 27.565-61.569 61.569"
              clipRule="nonzero"
            ></path>
          </clipPath>
          <g clipPath="url(#_clip2)">
            <path
              fill="#FFAE9E"
              fillRule="nonzero"
              d="M0 31.097l8.953 1.072 2.13-8.737S-6.659 24.673-5.165 12.33C-3.671-.012-.135-21.161 9.74-27.161c8.17-4.964 33.629-2.174 28.603 19.078-5.027 21.252-9.89 44.252-9.89 44.252s14.629 2.14 13.25 3.75c-1.511 1.764-28.792 23.303-34.5 22C-3.72 59.428 0 31.097 0 31.097"
              transform="translate(139.157 304.886)"
            ></path>
            <path
              fill="#FF8D80"
              fillRule="nonzero"
              d="M-5.873-15.504c-3.576 7.547.282 16.538.282 16.538s-.261-.158-.282.175l-4.385 2.726c-5.274-12.79 2.498-18.112 4.385-19.439"
              transform="scale(-1 1) rotate(-71.837 147.993 264.52)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0-42.236c-4.194.458-7.984 2.326-11.818 4.025a38.09 38.09 0 01-2.302.923c-.821 1.933-2.73 7.649 1.35 11.635 0 0-1.697 10.303-4.603 17.862 1.203.973 2.417 1.926 3.653 2.847 2.693-7.348 3.088-13.851 3.088-13.851s5.524-6.528 5.11-1.205c-.263 3.372-6.777 19.112-6.25 20 0 0 23.75-35.25 11.772-42.236"
              transform="translate(183.382 322.805)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0-5.643L-2.595 5.52l7.986-16.683L0-5.643z"
              transform="translate(173.705 318.785)"
            ></path>
            <path
              fill="#FFAE9E"
              fillRule="nonzero"
              d="M.278-1.015s-.046.141-.119.384c-.406 1.38-1.63 6.075.119 7.43 2.058 1.597 6.059 1.05 6.36-2.959.301-4.009.335-10.755-5.234-11.413"
              transform="scale(1 -1) rotate(-15.909 -1006.1 -771.099)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0 22.85s-3.95 2.366-5.581-.895c-1.63-3.261.951-3.669.951-3.669s-4.891 4.484-9.375 2.446c-4.483-2.038-.951-5.842-.951-5.842s-5.978 5.978-11.82 1.902c-5.843-4.076 2.174-8.424 2.174-8.424s-8.017 2.174-8.288-2.174C-33.162 1.846-22.157 4.7-22.157 4.7s-10.19-6.658-3.94-11.957 13.859 6.25 13.859 6.25S-13.461-7.121-10.2-7.8c3.261-.68 3.94 5.434 3.94 5.434s.679-7.608 6.522-6.385C6.104-7.529 3.794.623 3.794.623s1.359-7.336 7.337-5.706 2.582 9.375 2.582 9.375S17.381-.871 19.827.623c2.445 1.495-2.31 5.707-2.31 5.707s5.571-2.174 7.201 2.581c1.631 4.756-5.027 8.424-5.027 8.424s4.484-6.657 8.016-1.223c3.533 5.435-3.532 12.093-7.065 8.968-3.533-3.125 6.114 1.766 2.717 4.212-3.396 2.445-4.619-1.767-4.619-1.767s4.484 7.881-3.669 6.25C6.919 32.145 0 22.85 0 22.85"
              transform="translate(169.375 263.148)"
            ></path>
            <path
              fill="#FF8D80"
              fillRule="nonzero"
              d="M0 17.632c-2.428-.272-4.801-.752-7.165-1.262l-.721-.156.307-.734A116.43 116.43 0 01-5.83 11.5c.293-.663.614-1.313.927-1.966.316-.652.623-1.308.959-1.951C-2.633 4.997-1.25 2.444.365 0c-.84 2.807-1.912 5.504-3.056 8.169-.278.67-.584 1.326-.882 1.986-.3.66-.593 1.323-.914 1.973a116.424 116.424 0 01-1.933 3.894l-.413-.889c2.302.744 4.595 1.517 6.833 2.499"
              transform="translate(149.062 292.306)"
            ></path>
            <path
              fill="#FF8D80"
              fillRule="nonzero"
              d="M0 11.372c.213-.357.405-.721.558-1.086.07-.185.144-.364.185-.545.044-.177.064-.355.044-.497.01-.08-.036-.125-.045-.183-.012-.058-.071-.076-.086-.119-.038-.033-.099-.051-.135-.083-.05-.027-.134-.037-.194-.059-.124-.046-.318-.046-.471-.073l-.542.01-.54.01-.054-.6c-.054-.607-.042-1.19-.038-1.786a16.04 16.04 0 01.129-1.785c.073-.596.169-1.197.344-1.795.183-.597.373-1.211.801-1.801C.172.691.451.394.852.197c.199-.098.425-.159.648-.179.137.001.164-.018.391.009l.212.047c.074.019.133.028.22.056.105.04.209.085.31.133.219.088.36.22.508.348.288.264.473.588.598.908.112.323.176.643.21.956a5.7 5.7 0 010 .924 6.741 6.741 0 01-.371 1.746c-.081-1.155-.218-2.36-.719-3.212-.139-.191-.269-.367-.435-.465-.082-.048-.163-.107-.225-.106a.416.416 0 01-.09-.037c-.051-.011-.139-.013-.206-.02l-.217-.02c.084.011-.035.016-.042.018a.547.547 0 00-.176.076 1.23 1.23 0 00-.348.399c-.227.37-.407.874-.537 1.386a22.724 22.724 0 00-.317 1.611 45.618 45.618 0 00-.349 3.356l-.594-.59.681.09c.24.076.467.102.711.236.12.071.238.122.35.217.109.109.224.209.306.338.064.143.154.287.166.434.011.144.042.3.007.423a2.02 2.02 0 01-.246.687c-.11.199-.234.379-.373.545a5.151 5.151 0 01-.925.861"
              transform="translate(174.922 299.971)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 3.603s-.015-.2.013-.553c.033-.35.109-.855.331-1.438C.565 1.035.945.357 1.63-.172a3.365 3.365 0 011.212-.557 3.823 3.823 0 011.356-.037c.116.02.207.028.343.059l.284.069c.224.071.44.15.637.241.403.184.782.436 1.076.757.612.63.863 1.431.903 2.062a4.006 4.006 0 01-.163 1.493c-.104.342-.206.519-.206.519s-.006-.204-.046-.543a6.522 6.522 0 00-.292-1.327c-.177-.512-.482-1.034-.941-1.42A3.38 3.38 0 004.537.513L4.253.441 4.02.393C3.359.284 2.722.363 2.194.672c-.537.298-.959.785-1.277 1.239a8.988 8.988 0 00-.691 1.198A7.276 7.276 0 000 3.603"
              transform="translate(152.675 295.146)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 3.46s-.134-.736.123-1.839C.26 1.077.484.417 1.046-.171A2.466 2.466 0 012.16-.827c.447-.108.914-.08 1.326.024a3.32 3.32 0 011.141.527c.351.239.631.562.833.898.417.674.524 1.385.527 1.952.002 1.151-.359 1.821-.359 1.821s-.021-.184-.07-.494c-.05-.309-.132-.744-.271-1.229-.144-.483-.366-1.011-.707-1.443a2.755 2.755 0 00-.609-.551C3.73.52 3.49.435 3.187.337 2.635.185 2.156.259 1.735.588c-.428.32-.748.827-.997 1.267A13.695 13.695 0 000 3.46"
              transform="translate(140.945 292.799)"
            ></path>
            <path
              fill="#863F38"
              fillRule="nonzero"
              d="M0 .264S.08.012.327-.348c.124-.179.281-.398.494-.608.211-.211.459-.459.767-.664.3-.229.661-.408 1.053-.599.402-.144.837-.332 1.312-.394a5.681 5.681 0 011.484-.11c.515-.018 1.016.132 1.527.217.484.167.978.327 1.418.56.429.248.854.497 1.209.793.348.305.664.619.948.921.255.331.471.659.66.964.196.299.331.603.426.887.111.27.178.526.236.73.112.411.136.665.136.665s-.265-.056-.661-.186c-.196-.066-.438-.138-.68-.245-.244-.108-.532-.203-.832-.303a14.88 14.88 0 01-.904-.365 9.995 9.995 0 01-.957-.351c-.318-.125-.654-.194-.964-.334-.324-.095-.644-.185-.962-.288C5.714.887 5.4.76 5.078.717a4.29 4.29 0 01-.48-.105C4.437.589 4.277.576 4.116.541c-.321-.073-.641-.074-.96-.132-.314-.015-.632-.066-.929-.071C1.622.285 1.077.283.668.271L0 .264"
              transform="translate(153.221 289.105)"
            ></path>
            <path
              fill="#863F38"
              fillRule="nonzero"
              d="M0 4.207l-.463-.223c-.286-.13-.661-.311-1.092-.464a8.906 8.906 0 00-1.337-.422c-.225-.067-.462-.093-.691-.144-.232-.036-.47-.062-.708-.092-.247.001-.483-.057-.739-.046-.254.021-.506-.047-.767-.015-.258.016-.52-.006-.776-.002a9.483 9.483 0 01-.748.018c-.49-.027-.911.017-1.237-.017a4.987 4.987 0 01-.527-.051s.083-.179.269-.448c.193-.26.458-.663.888-1.004.214-.172.456-.35.722-.527.275-.16.592-.292.922-.424.322-.148.708-.194 1.084-.263.376-.083.79-.029 1.188-.029.4.062.809.129 1.185.273.379.143.739.309 1.048.537A4.11 4.11 0 01-.423 2.349c.279.511.385.989.423 1.322.039.335 0 .536 0 .536"
              transform="translate(150.191 284.755)"
            ></path>
            <path
              fill="#FF8D80"
              fillRule="nonzero"
              d="M0-4.601c-.434.866-.925 1.688-1.428 2.501l-.164-.628c1.305.352 2.653.645 3.984.864l1.002.151c.334.041.667.105 1.002.122.67.061 1.335.112 2.002.129.334.018.664-.022.997-.016a6.84 6.84 0 00.993-.055c.328-.039.665-.045.987-.114l.982-.17c.314-.099.637-.18.961-.27.335-.068.625-.233.945-.347.315-.126.643-.241.933-.422.297-.17.61-.329.929-.495-.231.262-.466.53-.718.788-.235.283-.535.477-.827.692-.294.21-.582.447-.916.59-.325.161-.65.334-.986.483l-1.042.341c-.347.135-.71.163-1.068.24-.36.071-.72.133-1.083.154-.362.029-.727.063-1.089.06L5.31-.018c-.361-.015-.719-.066-1.077-.102-.36-.03-.711-.107-1.067-.16a9.188 9.188 0 01-1.052-.222 16.75 16.75 0 01-2.051-.617 14.768 14.768 0 01-1.971-.855l-.51-.27.346-.358C-1.408-3.289-.733-3.967 0-4.601"
              transform="translate(143.985 318.531)"
            ></path>
            <path
              fill="#5997C0"
              fillRule="nonzero"
              d="M0-104.463c-1.446-.355-3.04-.711-4.732-1.066-2.464 3.255-8.251 4.786-13.391 8.754-3.204 2.475-7 8.5-7 8.5s-.591-7.937-2-11.75c-1.929-5.22-5-7.06-6.605-10.054-9.201-2.859-41.182 1.04-51.073 2.174-5.396 5.94-6.255 12.402-12.322 17.63.401.688-.409 1.287 0 1.75 4.989 5.65 36.878 2.737 40.239 2.995-.76 17.553-7.66 75.83-6.739 77.505 5.5 10 25.5 16.5 25.5 16.5L-34.6-2.878c.502 2.754 2.253 9.224 3.727 11.353 11.25-.25 33-7.25 33-7.25s6.617-58.704 8.034-74.674C12.822-72.838 0-104.463 0-104.463"
              transform="translate(174.983 445.33)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0-52.976c-11.631 4.925-23.684 8.803-35.709 12.63a385.599 385.599 0 01-15.19 4.502c-1.635 16.287-3.02 30.348-2.574 31.158 5.5 10 25.5 16.5 25.5 16.5L-24.45.461c.502 2.754 2.253 9.224 3.727 11.353 11.25-.25 33-7.25 33-7.25s5.473-48.548 7.53-69.354C14.416-59.404 7.722-56.246 0-52.976"
              opacity="0.2"
              transform="translate(164.833 441.991)"
            ></path>
            <path
              fill="#FFAE9E"
              fillRule="nonzero"
              d="M0 40.825C-3.462 31.891-12.466 8.077-13.229 6.286c1.321-4.13 2.25-9 .967-10.407-.385-.422-1.013 2.413-2.433 5.199-.586-6.473-2.713-15.025-3.178-15.087-1.49-.2-1.399 10.433-1.399 10.433s-1.822-12.385-2.929-11.489c-1.108.896-.424 11.04-.424 11.04s-2.97-10.594-4.043-9.456.787 11.105.787 11.105-1.643-5.999-2.157-6.547c-5.191-5.541 9.69 51.395 12.559 65.709 2.562-.821 5.641-2.085 9.364-5.124C-4.089 48.027-2.184 44.345 0 40.825"
              transform="translate(90.59 298.019)"
            ></path>
            <path
              fill="#FF8D80"
              fillRule="nonzero"
              d="M0-10.054c-.61-1.576-1.392-3.61-2.271-5.907a684.084 684.084 0 01-14.78 14.727c.669 2.907 1.214 5.356 1.572 7.141 2.562-.821 5.641-2.085 9.364-5.124C-4.089-2.852-2.184-6.534 0-10.054"
              transform="translate(90.59 348.898)"
            ></path>
            <path
              fill="#71AAC8"
              fillRule="nonzero"
              d="M-6.398 1.854h3.354s3.922-22.994 1.9-24.485c-4.695-3.466-11.739-1.118-11.739-1.118l6.485 25.603z"
              transform="scale(1 -1) rotate(-26.565 -672.102 -369.42)"
            ></path>
            <path
              fill="#FFAE9E"
              fillRule="nonzero"
              d="M0-60.082C.011-57.466-1.577-23.1-1.587-20.856c-2.766 3.174-5.466 7.158-4.863 8.906.181.524 1.831-1.763 4.168-3.7C-4.248-9.655-5.64-1.214-5.249-.979-3.998-.228.009-9.742.009-9.742S-3.128 1.999-1.797 1.626c1.332-.373 4.622-9.678 4.622-9.678S1.4 2.533 2.794 1.931C4.188 1.329 6.362-8.271 6.362-8.271s-.843 5.979-.595 6.666C8.265 5.33 16.888-51.146 19.833-65.008c-4.153-.404-9.91-.31-19.833 4.926"
              transform="translate(199.623 459.587)"
            ></path>
            <path
              fill="#FF8D80"
              fillRule="nonzero"
              d="M-10.705-3.533C-5.953-2.736-1.97-2.09.602-1.744c-1.015 4.046-3.04 9.437-11.307 17.023-.414-.15-1.705-.564-3.552-1.145.793-5.949 2.015-11.839 3.552-17.667"
              transform="scale(1 -1) rotate(70.359 388.678 -42.92)"
            ></path>
            <path
              fill="#5997C0"
              fillRule="nonzero"
              d="M0-60.254c8.436.825 18.121 2.691 23.574 5.918C32.93-48.799 47.859-7 47.859-7l-17.5 7-19.107-26.317L.297-44.575c.877-5.16-1.736-10.637-.297-15.679"
              transform="translate(170.251 400.055)"
            ></path>
            <path
              fill="#71AAC8"
              fillRule="nonzero"
              d="M10.813 17.522h3.227s3.362-21.809 1.389-23.224c-4.58-3.287-10.33.631-10.33.631l5.714 22.593z"
              transform="scale(-1 1) rotate(-80.205 134.462 331.905)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0-19.706C1.067-16.26 9.42.454 9.42.454s-.835-7.47-1.866-10.261c-1.929-5.22-5.001-7.06-6.606-10.055A14.21 14.21 0 00-.23-20.16c.093.13.175.276.23.454"
              opacity="0.2"
              transform="translate(140.306 355.112)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M-7.261-16.287c.638 2.593 1.407 5.476 1.644 8.636C-5.424-5.064-6.712.882-7.26 3.237c-1.526-3.823-3.018-7.938-4.081-12.17 1.5-2.367 2.83-4.839 4.08-7.354"
              opacity="0.2"
              transform="scale(-1 1) rotate(-48.054 318.28 340.084)"
            ></path>
            <path
              fill="#71AAC8"
              fillRule="nonzero"
              d="M-5.487-14.071H16.79L8.586-5.172-6.026-7.91l.539-6.161z"
              transform="scale(1 -1) rotate(-80.961 -121.038 -256.83)"
            ></path>
            <path
              fill="#71AAC8"
              fillRule="nonzero"
              d="M10.699-6.745h11.026L.762 10.369l-5.3-3.118L10.699-6.745z"
              transform="scale(-1 1) rotate(86.1 -263.255 81.219)"
            ></path>
            <path
              fill="#71AAC8"
              fillRule="nonzero"
              d="M-1.375-1.375a1.376 1.376 0 10.001 2.751 1.376 1.376 0 00-.001-2.751"
              transform="matrix(0 -1 -1 0 149.485 361.805)"
            ></path>
            <path
              fill="#71AAC8"
              fillRule="nonzero"
              d="M-1.375-1.375a1.376 1.376 0 10.001 2.751 1.376 1.376 0 00-.001-2.751"
              transform="matrix(0 -1 -1 0 146.36 383.18)"
            ></path>
            <path
              fill="#71AAC8"
              fillRule="nonzero"
              d="M-1.369-1.503a1.375 1.375 0 100 2.75 1.375 1.375 0 000-2.75"
              transform="scale(-1 1) rotate(-84.638 151.529 281.869)"
            ></path>
            <path
              fill="#71AAC8"
              fillRule="nonzero"
              d="M-1.369-1.503a1.375 1.375 0 100 2.75 1.375 1.375 0 000-2.75"
              transform="scale(-1 1) rotate(-84.638 163.933 292.043)"
            ></path>
          </g>
        </g>
        <path
          fill="#00728A"
          fillRule="nonzero"
          d="M0 1.18l12.318-6.14 5.049 17.458 36.738-34.662 8.401 18.355-47.052 27.153L0 1.18z"
          transform="translate(93.041 202.657)"
        ></path>
        <path
          fill="#B8D7F4"
          fillRule="nonzero"
          d="M-61.569-61.569c-34.004 0-61.569 27.565-61.569 61.569 0 34.004 27.565 61.569 61.569 61.569C-27.566 61.569 0 34.004 0 0c0-34.004-27.566-61.569-61.569-61.569"
          transform="matrix(0 -1 -1 0 157.043 29.391)"
        ></path>
        <g transform="translate(20.334 1.788)">
          <clipPath id="_clip3">
            <path
              d="M198.278 89.172c0 34.004-27.565 61.569-61.569 61.569-34.004 0-61.569-27.565-61.569-61.569 0-34.003 27.565-61.569 61.569-61.569 34.004 0 61.569 27.566 61.569 61.569"
              clipRule="nonzero"
            ></path>
          </clipPath>
          <g clipPath="url(#_clip3)">
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0-67.272s-9.864-7.009-19.988 8.047c-10.124 15.056 4.413 18.171-4.413 28.555S-40.106-7.047-19.08.61C-1.509 7.009 8.047-7.307 8.047-7.307L0-67.272z"
              transform="translate(134.277 118.061)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0-64.651s-.3-.223-.916-.544a12.22 12.22 0 00-2.825-.988c-1.256-.284-2.851-.348-4.596.008a14.195 14.195 0 00-5.421 2.421c-.473.278-.893.655-1.33 1.023-.427.38-.909.712-1.312 1.15-.815.865-1.713 1.703-2.462 2.728l-1.189 1.495c-.363.54-.772 1.08-1.111 1.639a23.74 23.74 0 00-1.883 3.541 13.56 13.56 0 00-.846 8.146c.268 1.403.705 2.798 1.072 4.258.364 1.452.616 3.025.388 4.601-.234 1.572-.935 3.028-1.814 4.277-.885 1.269-1.983 2.301-2.93 3.382-1.939 2.156-3.72 4.404-5.136 6.758-1.411 2.345-2.488 4.814-2.804 7.281-.342 2.46.17 4.858 1.265 6.738 1.067 1.904 2.523 3.354 3.91 4.486 1.395 1.134 2.75 1.944 3.867 2.601 1.139.619 2.04 1.1 2.675 1.386l.964.462-.987-.41c-.649-.257-1.582-.676-2.748-1.261-1.148-.615-2.546-1.376-4.009-2.474-1.456-1.092-3.019-2.52-4.179-4.499-1.197-1.942-1.814-4.501-1.51-7.118.273-2.624 1.333-5.235 2.783-7.654 1.44-2.436 3.234-4.736 5.188-6.943.972-1.124 2.027-2.136 2.828-3.31.81-1.17 1.412-2.474 1.611-3.852.437-2.792-.867-5.596-1.385-8.531-.275-1.472-.354-2.993-.182-4.468.168-1.477.587-2.893 1.149-4.203.573-1.309 1.232-2.538 2.012-3.67.371-.578.779-1.088 1.159-1.626l1.263-1.515c.787-1.024 1.731-1.867 2.586-2.73.422-.437.929-.772 1.376-1.151.46-.367.899-.742 1.396-1.02 1.853-1.307 3.876-2.009 5.677-2.316 1.815-.296 3.444-.165 4.71.174 1.273.329 2.199.794 2.807 1.134.602.356.889.594.889.594"
              transform="translate(134.867 116.171)"
            ></path>
            <path
              fill="#FF9E7B"
              fillRule="nonzero"
              d="M0-37.096s-1.33 13.252.148 14.239c1.478.986 9.37 1.825 9.37 1.825s-4.186 20.468-20 20.75c-15.814.282-22.477-15.95-22.477-15.95l15.37-4.553-1.478-16.212L0-37.096z"
              transform="translate(152.592 128.587)"
            ></path>
            <path
              fill="#000E23"
              fillRule="nonzero"
              d="M0 6.906c-.464.153-.931.299-1.402.432-3.12.882-8.793 2.192-11.993 2.689-1.29.135-2.582.249-3.875.355l-1.254-13.759L.543-3.476S-.074 2.673 0 6.906"
              opacity="0.1"
              transform="translate(152.049 94.967)"
            ></path>
            <path
              fill="#FF9E7B"
              fillRule="nonzero"
              d="M0-47.916s-8.557-2.439-17.273 4.099c-12.882 9.661-12.296 46.256 6.587 44.939C-2.688.564 3.596-5.124 8.973-11.363c1.093-1.268 4.94-.036 5.676-1.574 1.064-2.225 2.272-4.607 2.371-7.425C17.606-37.05 0-47.916 0-47.916"
              transform="translate(144.168 101.004)"
            ></path>
            <path
              fill="#F18569"
              fillRule="nonzero"
              d="M0 9.569c.484-.219.963-.434 1.404-.7.423-.265.882-.568 1.051-.91a.456.456 0 00.056-.211c-.012-.06-.015-.114-.065-.181-.078-.132-.245-.272-.425-.401-.378-.258-.839-.46-1.305-.643l-.293-.114-.012-.324C.38 5.237.365 4.407.444 3.563.522 2.72.62 1.876.996 1.014c.097-.215.217-.437.429-.641C1.62.168 1.99 0 2.316.059c.081.008.148.036.222.056l.053.017c.11.05.049.025.083.042l.066.04.262.162c.171.082.356.275.522.465.189.178.269.391.366.597.11.199.121.43.181.637.056.43.059.858-.047 1.264a4.566 4.566 0 00-.268-1.179c-.099-.17-.148-.363-.28-.501-.121-.142-.213-.311-.355-.392-.124-.101-.221-.213-.442-.283L2.396.86l-.07-.032c.01.005-.073-.032.014.007L2.319.833c-.027 0-.057-.013-.079-.009-.088.007-.126.038-.207.133a1.575 1.575 0 00-.207.406c-.23.672-.301 1.488-.362 2.276-.037.795-.079 1.611-.058 2.405l-.304-.438c.496.233.989.489 1.456.858.227.197.457.424.609.767.168.341.101.838-.123 1.102-.209.278-.461.443-.708.584a4.08 4.08 0 01-.766.32A7.336 7.336 0 010 9.569"
              transform="translate(154.698 77)"
            ></path>
            <path
              fill="#F18569"
              fillRule="nonzero"
              d="M0 12.273c-1.332-.923-2.545-1.966-3.725-3.042l-.198-.18.082-.296a54.607 54.607 0 011.438-4.448C-1.857 2.845-1.271 1.397-.525 0c-.211 1.57-.581 3.088-.992 4.592a54.608 54.608 0 01-1.432 4.45l-.115-.476C-1.982 9.74-.931 10.946 0 12.273"
              transform="translate(129.496 75.495)"
            ></path>
            <path
              fill="#fff"
              fillRule="nonzero"
              d="M0-4.672l14.926-.909S8.307.909 0-4.672"
              transform="translate(126.143 95.87)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 3.896s-.207-.046-.532-.124l-1.271-.316a64.6 64.6 0 00-1.609-.39l-.844-.171c-.284-.027-.561-.114-.842-.119-.279-.054-.556-.064-.832-.079-.277-.038-.548-.011-.823-.026-.277-.031-.539.031-.809.026-.273-.011-.525.041-.775.063-.244.035-.504.012-.711.061-.208.043-.41.058-.576.078-.334.036-.555.039-.555.039s.072-.191.25-.492c.091-.148.2-.338.35-.53.135-.213.343-.386.564-.594.227-.199.477-.426.793-.582.312-.157.637-.372 1.02-.46.376-.105.772-.237 1.189-.243.207-.015.417-.036.628-.037l.628.054c.839.094 1.624.399 2.28.774A5.908 5.908 0 01-.925 2.139c.374.463.617.897.751 1.225.14.322.174.532.174.532"
              transform="translate(143.182 69.325)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 3.845s-.033-.164-.072-.45c-.008-.145-.063-.31-.035-.519.021-.207.038-.456.097-.721.097-.244.161-.543.34-.803C.5 1.084.695.8.986.587a1.77 1.77 0 01.447-.308c.163-.086.323-.196.51-.231.361-.127.742-.166 1.128-.177.381.041.761.099 1.107.25.182.052.342.146.493.252.151.105.312.187.431.321.126.124.254.241.365.367l.262.414c.191.254.232.563.324.808.072.255.063.503.083.709.032.205-.033.389-.046.534-.05.291-.09.448-.09.448s-.06-.164-.143-.431c-.049-.132-.068-.298-.162-.465-.09-.168-.155-.364-.269-.55-.143-.171-.228-.39-.413-.548a57.14 57.14 0 00-.252-.257c-.105-.066-.199-.143-.299-.217a2.668 2.668 0 00-.679-.323c-.238-.097-.498-.114-.752-.139-.257.001-.513.01-.755.086-.128.015-.234.084-.353.122a.996.996 0 00-.328.166 2.678 2.678 0 00-.561.45c-.17.164-.278.372-.423.54l-.276.533c-.091.164-.122.348-.176.478A7.58 7.58 0 010 3.845"
              transform="translate(134.449 75.603)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 3.504l-1.651-.383c-.455-.104-.952-.221-1.452-.316l-.749-.122c-.249-.013-.493-.077-.737-.066-.244-.038-.484-.019-.725-.023-.244-.023-.477.038-.722.034-.244 0-.475.074-.718.088-.246.007-.465.093-.692.126-.221.047-.455.057-.639.119-.186.056-.37.087-.52.119-.303.061-.505.081-.505.081s.044-.182.173-.475c.132-.291.323-.725.706-1.124.192-.198.384-.446.661-.616.272-.173.55-.398.893-.523.341-.121.694-.294 1.084-.325A4.368 4.368 0 01-4.417.034c.793.039 1.539.305 2.154.64A5.139 5.139 0 01-.824 1.877c.34.43.558.831.675 1.134.123.299.149.493.149.493"
              transform="translate(128.804 68.63)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 4.117s-.041-.162-.093-.446c-.015-.144-.079-.307-.061-.516.012-.208.017-.458.063-.726.085-.248.135-.549.301-.818.157-.276.338-.569.618-.796.123-.133.271-.237.432-.329.159-.094.313-.211.499-.256.354-.143.733-.201 1.117-.23.383.022.765.062 1.118.196.184.043.349.129.505.229.155.097.321.171.446.299.131.118.264.229.381.349l.282.401c.203.245.259.551.363.792.084.25.087.499.117.704.042.203-.014.39-.02.535a6.836 6.836 0 01-.069.452s-.067-.16-.163-.424c-.056-.129-.082-.293-.184-.456-.098-.163-.173-.356-.296-.536-.151-.164-.246-.38-.439-.528l-.263-.245c-.109-.06-.206-.133-.309-.202a2.675 2.675 0 00-.694-.29c-.243-.085-.503-.09-.759-.103-.256.014-.511.035-.75.123-.127.021-.229.094-.346.139a.986.986 0 00-.319.181c-.208.133-.379.3-.539.477-.163.171-.26.384-.397.56l-.25.545c-.083.168-.105.353-.152.486A8.499 8.499 0 010 4.117"
              transform="translate(120.379 74.657)"
            ></path>
            <path
              fill="#FF9E7B"
              fillRule="nonzero"
              d="M0 2.965l-38.569 8.515c-.735-9.678-1.639-24.826-2.35-31.574 1.972-1.807 4.39-4.151 3.88-4.336-.956-.346-2.674.454-4.2 1.377-.444-3.985-.736-8.864-.921-9.411-.581-1.709-1.789 5.35-1.789 5.35s-.046-8.563-.889-8.719c-.843-.156-1.447 9.372-1.447 9.372s-.576-8.755-1.6-8.826c-.954-.067-1.875 9.114-1.997 10.367.091-.972.578-6.91-.999-8.304-2.013-1.779-3.24 53.386-3.129 53.39.042.48.097.934.149 1.395-.098 1.459.055 2.681.523 3.581 7.186 13.812 57.769-1.912 57.769-1.912L0 2.965z"
              transform="translate(122.929 108.575)"
            ></path>
            <path
              fill="#FF9E7B"
              fillRule="nonzero"
              d="M0-65.896c7.431-3.94 52.879 25.569 51.5 37.75C50.495-19.274 32.056-3.512 21.496 3.94 18.904 1.92 18.923-.156 16.5-2.396c7.017-8.793 15.5-25 15.5-25s-18.204-6.529-31.25-14-.75-24.5-.75-24.5"
              transform="translate(161.36 173.701)"
            ></path>
            <path
              fill="#FF6A59"
              fillRule="nonzero"
              d="M0-48.258c-2.977-.219-4.998-.004-4.998-.004S-10-32.258-21.25-32.508c-14.283-.317-13.375-12.761-13.375-12.761s-2.512.602-5.325 1.461c-2.098 4.431-9.248 13.738-10.05 23.3l.02.007c-.49 7.922 6.195 15.651 8.212 17.525C-39.136-.533-33.309.219-23.535-.345-13.761-.909.525-3.822.525-3.822L3.82-13.833l2.257-5.49.397.232C-4.291-29.309-1.756-43.201 0-48.258"
              transform="translate(162.86 154.813)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0-42.572s-3.894 10.903 8.307 13.239c12.2 2.336 2.077 11.682 14.537 12.72 0 0-.519-8.567 4.153-7.788 4.673.779 4.673 16.527-4.845 13.931 0 0-5.019 15.143-.866 18.518 4.154 3.374 14.018-1.039 22.844 4.672 0 0 7.528-8.047 3.115-13.239-4.413-5.191-12.72-4.932-11.422-11.941s-3.634-9.085-3.634-9.085 6.749-20.248-8.566-26.998C8.307-55.292 1.038-51.398 0-42.572"
              transform="translate(130.643 98.553)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0 32.515s.363-.569.899-1.613c.265-.523.582-1.161.882-1.917.318-.748.59-1.623.855-2.574.207-.961.421-2.021.325-3.123-.083-1.096-.427-2.204-1.102-3.15-.701-.888-1.804-1.67-2.862-2.292-1.105-.647-2.34-1.129-3.585-1.613-1.243-.492-2.525-.95-3.744-1.598-1.212-.626-2.367-1.594-2.941-2.871-.113-.324-.289-.632-.339-.962a22.337 22.337 0 01-.166-.975c.006-.654.003-1.255.033-1.824a9.87 9.87 0 00-.297-3.13c-.473-1.908-1.588-3.228-2.494-3.939C-15.456.219-16.109 0-16.109 0s.678.126 1.691.77a7.267 7.267 0 011.6 1.451 7.008 7.008 0 011.336 2.486c.313.972.511 2.156.494 3.305 0 .587.038 1.173.058 1.732l.177.832c.048.283.209.524.304.793.494 1.033 1.467 1.798 2.588 2.382 1.127.587 2.389 1.028 3.648 1.515 1.255.501 2.533 1.01 3.711 1.713 1.209.731 2.239 1.51 3.098 2.622.786 1.131 1.093 2.452 1.119 3.657.037 1.218-.249 2.319-.518 3.309-.623 1.966-1.507 3.431-2.123 4.435C.437 31.999 0 32.515 0 32.515"
              transform="translate(178.043 77.125)"
            ></path>
          </g>
        </g>
        <path
          fill="#00728A"
          fillRule="nonzero"
          d="M0 1.18l12.318-6.14 5.049 17.458 36.738-34.662 8.401 18.355-47.052 27.153L0 1.18z"
          transform="translate(173.32 45.289)"
        ></path>
        <path
          fill="#B8D7F4"
          fillRule="nonzero"
          d="M-61.569-61.569c-34.004 0-61.569 27.565-61.569 61.568 0 34.004 27.565 61.57 61.569 61.57C-27.565 61.569 0 34.003 0-.001c0-34.003-27.565-61.568-61.569-61.568"
          transform="matrix(0 -1 -1 0 339.241 60.655)"
        ></path>
        <g transform="translate(-31.136 46.506)">
          <clipPath id="_clip4">
            <path
              d="M431.946 75.718c0 34.004-27.565 61.569-61.568 61.569-34.004 0-61.57-27.565-61.57-61.569 0-34.004 27.566-61.569 61.57-61.569 34.003 0 61.568 27.565 61.568 61.569"
              clipRule="nonzero"
            ></path>
          </clipPath>
          <g clipPath="url(#_clip4)">
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0-42.728l2.843 12.463 26.444 5.076L.202 12.066-34.125-24.12l17.79-5.284-3.808-25.39L0-42.728z"
              transform="translate(370.514 128.947)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 22.673c-6.469-.567-11.73-2.994-15.773-7.893L-18.057-.452 2.086 11.614l2.626 11.511c-1.581-.146-3.156-.316-4.712-.452"
              opacity="0.2"
              transform="translate(368.428 74.605)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M-10.283-40.885s-33.75 21.638-29.3 34.966c3.068 9.194 16.42 16.855 29.3 8.91C4.275-5.99 17.275-16.154 13.94-25.128 10.607-34.1 2.501-47.777-10.283-40.885"
              transform="scale(-1 1) rotate(-40.361 -74.155 505.371)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0-7.981s-5.367-7.981-8.772-2.526C-12.176-5.051-2.845 7.981.934 2.164 4.712-3.653 0-7.981 0-7.981"
              transform="translate(350.953 75.473)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0-20.253c2.405 5.139-1.447 8.68-1.447 8.68L-.46 2.804l-2.17.454-1.75-6.714c-3.246-5.463-6.554-1.786-6.554-1.786-2.938-3.319-10.06-13.195-1.446-18.269 4.369 2.865 7.799.741 12.38 3.258"
              transform="translate(353.51 69.53)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 16.159s13.713 4.367 17.556 3.144c3.843-1.222.437-3.755.437-3.755s8.123 3.406 10.656 1.659c2.533-1.747-2.009-5.066-2.009-5.066s9.957-.786 6.987-5.939c-2.969-5.153-11.18-3.057-11.18-3.057s7.512-1.398 5.503-5.153C25.941-5.764 14.674 0 14.674 0s6.813-3.493 4.542-6.026-9.346.611-12.84 4.716c0 0 5.241-3.319 3.668-6.114C8.472-10.219 1.66-5.502-1.397.525c-3.058 6.026 3.668-4.368.524-5.066-3.145-.699-11.681 4-10.688 17.46C-10.569 26.378 0 16.159 0 16.159"
              transform="translate(352.692 33.1)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 .747S-.225.762-.581.78L-1.98.839l-1.782.076-.932.069c-.306.054-.622.043-.92.117l-.457.057c-.145.043-.295.065-.443.095-.303.042-.583.136-.873.201-.299.047-.56.171-.841.243-.287.064-.54.176-.791.268-.245.099-.518.148-.722.248-.205.098-.409.167-.576.231-.336.125-.563.185-.563.185s.029-.215.141-.571c.057-.176.125-.399.232-.636.093-.25.261-.484.44-.755.183-.265.391-.556.676-.798.28-.245.566-.54.934-.732.357-.209.732-.441 1.154-.561.208-.07.417-.149.632-.207l.654-.116c.88-.135 1.765-.054 2.543.133.78.198 1.454.517 1.979.865.525.355.908.72 1.148 1.011.245.283.347.485.347.485"
              transform="translate(368.103 57.55)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 2.108l-.504-.045-1.198-.122c-.469-.045-.983-.096-1.495-.126l-.765-.024c-.252.019-.505-.012-.747.031-.25-.006-.487.044-.729.072-.247.009-.472.101-.718.128-.245.032-.466.136-.707.183-.246.038-.453.153-.676.216-.215.076-.448.117-.625.203-.178.08-.358.135-.504.187-.295.101-.495.148-.495.148s.02-.189.111-.499c.094-.309.228-.767.56-1.217.165-.223.325-.496.58-.704.25-.208.499-.47.825-.64.326-.165.657-.385 1.043-.467.376-.123.77-.201 1.168-.218.799-.065 1.58.103 2.24.359.661.264 1.198.64 1.599 1.015.396.385.666.758.823 1.047.163.282.214.473.214.473"
              transform="translate(381.046 54.756)"
            ></path>
            <path
              fill="#863F38"
              fillRule="nonzero"
              d="M0-18.173s.927.99 2.1 2.638a37.473 37.473 0 011.859 2.912c.621 1.103 1.239 2.311 1.737 3.584.238.641.46 1.292.615 1.959.143.676.255 1.333.173 2.058-.069.597-.207 1.284-.473 1.818-.266.541-.577 1.021-.937 1.394A6.575 6.575 0 013.101-.345C2.567-.09 2.243 0 2.243 0s.312-.13.803-.444c.48-.32 1.17-.808 1.76-1.59.306-.38.549-.852.745-1.36.19-.526.276-1.044.309-1.696-.038-.532-.189-1.162-.396-1.753a18.779 18.779 0 00-.716-1.804c-.524-1.201-1.122-2.383-1.691-3.488a7876.88 7876.88 0 00-1.564-3.031A123.183 123.183 0 010-18.173"
              transform="translate(370.625 77.471)"
            ></path>
            <path
              fill="#863F38"
              fillRule="nonzero"
              d="M0-1.513c-.625-1.055-.647-2.491-.026-3.674.304-.591.839-1.157 1.613-1.362.21-.045.34-.077.635-.053.041.007.064.006.122.019.075.022.15.048.224.08.16.067.208.124.315.193.334.273.52.57.693.86.339.582.539 1.18.734 1.772.192.592.342 1.19.465 1.79a29.27 29.27 0 01.536 3.621l.023.255-.163.158-.455.442c-.163.144-.271.309-.411.464-.145.154-.222.319-.341.481l-.099.242c-.037.083-.019.146-.041.225.007.135.04.274.174.394.12.125.304.222.497.298.389.157.831.247 1.275.298a3.946 3.946 0 01-1.37.052 1.74 1.74 0 01-.709-.25 1.065 1.065 0 01-.495-.71c-.006-.141-.035-.307-.005-.437l.09-.398c.105-.22.19-.479.327-.671.135-.193.256-.416.409-.588l.444-.543-.14.413a84.627 84.627 0 00-.716-3.484c-.287-1.135-.555-2.311-1.03-3.281-.113-.236-.253-.46-.381-.577-.082-.093-.157-.103-.402-.083-.423.045-.839.364-1.155.792-.63.867-.91 2.083-.637 3.262"
              transform="translate(343.513 71.52)"
            ></path>
            <path
              fill="#fff"
              fillRule="nonzero"
              d="M-15.167-6.682s6.807 6.719 15.045 0"
              transform="scale(-1 1) rotate(2.301 -2383.759 -9021.451)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M-1.042-1.165c-1.399 0-2.533.47-2.532 1.048C-3.575.462-2.441.931-1.042.931S1.491.461 1.491-.117C1.492-.696.357-1.165-1.042-1.165"
              transform="scale(-1 1) rotate(-83.563 -153.265 241.176)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M-1.042-1.165c-1.399 0-2.533.47-2.532 1.048C-3.575.462-2.441.931-1.042.931S1.491.461 1.491-.117C1.492-.696.357-1.165-1.042-1.165"
              transform="scale(-1 1) rotate(-83.563 -145.281 234.183)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0-59.836c-.521.542-3.008 6.203-3.008 6.203S.16-63.265-.752-64.535c-.912-1.271-5.263 8.834-5.263 8.834s2.025-9.962 1.047-10.998c-.979-1.035-4.431 10.998-4.431 10.998s2.171-9.439.667-9.439c-.471 0-2.848 8.426-4.486 14.447l-.028.04c-1.057-3.042-2.472-6.742-3.028-6.52-.566.226.233 5.653.954 9.912-6.365 11.057-17.572 31.615-17.572 31.615l-40.213-5.306L-75.898.83s43.318 7.068 50.916 1.28c6.426-4.895 16.154-32.624 20.397-45.468C-.993-52.536 2.835-62.785 0-59.836"
              transform="translate(457.342 122.945)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M-30.287-19.111H.502L.421 2.849s-16.75.511-31.149-.686c-1.568-7.19-2.249-14.443.441-21.274"
              opacity="0.2"
              transform="scale(-1 1) rotate(-7.516 730.06 2970.318)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M-23.795-36.774h38.506c2.772 6.232 5.207 12.631 8.38 18.631-12.712 4.369-46.805 3.329-46.805 3.329l-.081-21.96z"
              transform="scale(-1 1) rotate(53.612 -323.32 -260.317)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M-1.108.004C7.69 9.342 15.618 19.33 15.618 19.33L-1.108 33.56l-15.438-18.282C-11.813 9.7-7.037 3.986-1.108.004"
              opacity="0.2"
              transform="scale(1 -1) rotate(3.792 2382.524 4894.932)"
            ></path>
            <path
              fill="#AA534F"
              fillRule="nonzero"
              d="M0 62.956c1.03.615 6.002 4.295 11.611-.553 1.418-6.5-5.17-11.565-5.563-18.03l-.191.047S-7.598 25.259-15.17 14.99c.236-4.313.418-9.796-.17-9.956-.578-.158-1.566 3.678-2.273 6.82a1.055 1.055 0 01-.032-.037c-2.308-5.798-5.62-13.902-6.088-13.848-1.494.169 1.728 9.303 1.728 9.303s-4.788-11.567-5.643-10.428c-.855 1.139 2.281 10.81 2.281 10.81s-5.463-9.55-6.227-8.185c-.763 1.366 3.472 10.579 3.472 10.579s-3.11-5.345-3.688-5.824c-3.151-2.611 1.81 7.141 6.414 15.855C-19.731 32.363-6.937 58.817 0 62.956"
              transform="translate(301.307 99.626)"
            ></path>
            <path
              fill="#FFA855"
              fillRule="nonzero"
              d="M0-44.999s3.227.378 7.769.691c4.021-9.058 5.783-17.335 2.749-26.829-12.624-2.573-24-4.167-29.333-5 0 0-10.834 21.666-15.084 21.916-2.26.133-15.5-11-20.583-20.75 0 0-2.727-.005-13.455 3.593-7.687 2.578-14.212 11.907-22.212 25.657 5.896 5.66 14.609 12.723 22.54 15.668l3.039-4.665s2.084 49.144 5.25 57.5c3.167 8.356 63.75 7.25 62.25-8C.408-24 0-44.999 0-44.999"
              transform="translate(400.509 175.526)"
            ></path>
          </g>
        </g>
        <path
          fill="#00728A"
          fillRule="nonzero"
          d="M0 1.181l12.318-6.14 5.049 17.458 36.738-34.663 8.402 18.356-47.053 27.153L0 1.181z"
          transform="translate(357.656 82.819)"
        ></path>
        <path
          fill="#B8D7F4"
          fillRule="nonzero"
          d="M-61.569-61.569c-34.003 0-61.568 27.565-61.568 61.57 0 34.002 27.565 61.568 61.568 61.568C-27.565 61.569 0 34.003 0 .001c0-34.005-27.565-61.57-61.569-61.57"
          transform="matrix(0 -1 -1 0 415.017 208.763)"
        ></path>
        <g transform="matrix(-1 0 0 1 781.962 -37.303)">
          <clipPath id="_clip5">
            <path
              d="M305.376 307.636c0 34.003 27.565 61.568 61.569 61.568 34.003 0 61.569-27.565 61.569-61.568 0-34.004-27.566-61.569-61.569-61.569-34.004 0-61.569 27.565-61.569 61.569"
              clipRule="nonzero"
            ></path>
          </clipPath>
          <g clipPath="url(#_clip5)">
            <path
              fill="#5997C0"
              fillRule="nonzero"
              d="M0-50.572c.583-.388 12.039-8.544 20 2.136s-2.136 17.67 6.602 25.048c8.738 7.379 5.243 21.748-.583 25.049 0 0-7.572-2.718-14.951.388-7.379 3.107-17.509 6.495-37.864.389-9.709-2.913-17.282-1.165-17.282-1.165s-5.996-8.899 2.525-14.758c6.213-4.271 3.495-10.679 3.495-10.679l6.019-2.525L0-50.572z"
              transform="translate(376.642 326.909)"
            ></path>
            <path
              fill="#5997C0"
              fillRule="nonzero"
              d="M0 53.701l.546-.554c.39-.337.791-.963 1.286-1.732.458-.808.943-1.83 1.283-3.075l.262-.959.182-1.037c.152-.703.183-1.463.263-2.244.035-1.568.019-3.281-.383-5.022-.392-1.727-.968-3.533-1.997-5.144-1.01-1.61-2.331-3.094-3.98-4.209l-1.311-.999c-.212-.172-.425-.303-.639-.509l-.638-.654-.632-.665-.504-.745c-.162-.251-.342-.494-.487-.756l-.367-.825c-.934-2.248-.915-4.614-.913-6.714-.002-2.121.007-4.13-.438-5.992-.439-1.859-1.268-3.522-2.224-5.018-.552-.679-1.083-1.43-1.613-2.001-.621-.594-1.184-1.298-1.801-1.704l-.884-.689c-.307-.204-.635-.365-.936-.552-.595-.404-1.238-.609-1.827-.858-.591-.244-1.19-.317-1.726-.472-.549-.08-1.062-.115-1.525-.168-.927.013-1.672-.001-2.169.091l-.772.091.752-.198c.489-.154 1.227-.275 2.184-.374.479.004 1.015-.014 1.598.01.571.099 1.218.116 1.869.306.649.195 1.369.351 2.048.711.348.162.711.319 1.066.515l1.047.696c.766.455 1.308 1.058 1.992 1.619.706.658 1.246 1.372 1.903 2.097 1.134 1.565 2.183 3.386 2.728 5.474.552 2.078.656 4.288.659 6.408.01 2.12.065 4.195.828 5.954l.279.66c.113.209.26.401.386.603l.39.602.479.507.471.515c.165.172.41.331.611.497l1.222.956c1.879 1.285 3.312 3.068 4.391 4.904 1.11 1.846 1.622 3.877 1.944 5.768.344 1.907.224 3.74.058 5.39-.15.822-.229 1.616-.467 2.341l-.302 1.063-.377.973a13.915 13.915 0 01-1.66 3.046c-.257.405-.598.699-.84.978-.24.289-.495.468-.698.621L0 53.701"
              transform="translate(406.903 273.051)"
            ></path>
            <path
              fill="none"
              fillRule="nonzero"
              stroke="#fff"
              strokeWidth="1"
              d="M3.91-9.475c1.468.597 2.694 1.964 3.166 3.76.747 2.838-.67 5.672-3.166 6.329s-5.125-1.112-5.872-3.95c-.664-2.523.381-5.042 2.374-6.033"
              transform="scale(-1 1) rotate(14.746 -1440.557 -1365.954)"
            ></path>
            <path
              fill="#FFA393"
              fillRule="nonzero"
              d="M0 1.053l-4.591-25.776 20.473 10.63L17.217-.544l10.841.996s10.533 15.237-10.175 20.281C-2.825 25.776-7.561 3.621-7.561 3.621L0 1.053z"
              transform="translate(362.592 336.399)"
            ></path>
            <path
              fill="#FFAE9E"
              fillRule="nonzero"
              d="M0-25.213c-5.949-19.83-26.493-14.276-36.726-9.962 2.574 10.861-.584 12.092 3.299 28.791C-16.922 19.83 9.379 6.046 0-25.213"
              transform="translate(390.845 319.808)"
            ></path>
            <path
              fill="#FFAE9E"
              fillRule="nonzero"
              d="M0-13.684c.994-.344 2.432.538 3.397 1.046.246 4.086.704 7.301 1.615 11.254a3.771 3.771 0 01-1.365.85C1.109.344-1.765-1.889-2.772-5.52c-1.007-3.631.234-7.286 2.772-8.164"
              transform="translate(352.944 315.883)"
            ></path>
            <path
              fill="#5997C0"
              fillRule="nonzero"
              d="M0-11.273s-11.485-2.861-14.786 8.207c-2.559 8.578-3.707 17.507-9.233 13.317-5.525-4.191-8.437 5.13-5.136 9.402 0 0-12.039-2.136-13.01-11.845-.971-9.709 10.097-6.214 8.544-16.893-1.554-10.68 17.144-21.841 29.903-13.398C5.667-16.273 0-11.273 0-11.273"
              transform="translate(380.36 292.995)"
            ></path>
            <path
              fill="#5997C0"
              fillRule="nonzero"
              d="M0-50.654c-2.245-1.322-4.677-2.249-7.209-2.635a19.982 19.982 0 00-3.805-.245c-1.269.016-2.535.14-3.777.383-2.483.466-4.898 1.308-7.087 2.526-2.183 1.216-4.184 2.781-5.662 4.721-1.473 1.922-2.391 4.256-2.191 6.573l.037 2.014-.138 1.11a4.2 4.2 0 01-.233 1.086c-.333 1.439-1.292 2.767-2.391 3.683-1.104.938-2.311 1.569-3.38 2.221-1.08.642-1.993 1.351-2.581 2.268a6.058 6.058 0 00-.942 3.204l-.04.836.132 1.455.1.416.19.836.026.104a10.124 10.124 0 01-.06 3.777 8.88 8.88 0 01-.58 1.817c-.264.578-.568 1.143-.952 1.647-.179.262-.38.506-.596.736a6.31 6.31 0 01-.66.668c-.224.22-.463.419-.714.601-.245.191-.491.38-.758.532l-.735.458-.732.475c-.237.161-.505.309-.716.481l-.602.531c-.43.325-.764.764-1.118 1.185a3.326 3.326 0 00-.474.671l-.434.703-.323.762a3.423 3.423 0 00-.267.786c-.067.27-.147.539-.204.813l-.104.836c-.05.279-.028.563-.038.846-.004.283-.014.568.023.851.031.57.124 1.134.228 1.7.13.559.263 1.12.439 1.672.186.547.381 1.096.639 1.618A13.682 13.682 0 01-49.114.865a13.668 13.668 0 01-.376-1.733c-.061-.292-.075-.589-.095-.887-.014-.298-.059-.596-.031-.896l.04-.902c.037-.299.099-.595.149-.895a3.95 3.95 0 01.223-.882l.291-.87c.298-.529.504-1.13.899-1.602.365-.493.713-1.001 1.191-1.416l.676-.656c.23-.205.468-.353.701-.532l.704-.516.717-.507c.231-.15.437-.328.645-.503a4.99 4.99 0 00.597-.546c.194-.187.379-.38.54-.594.175-.201.336-.413.475-.639 1.2-1.756 1.494-3.993 1.1-6.076l.025.104-.268-1.057-.128-.53-.056-.483-.113-.967c-.023-.462.006-.734.008-1.11-.012-1.388.358-2.905 1.173-4.17.811-1.283 2.031-2.194 3.165-2.881 1.14-.702 2.242-1.307 3.109-2.063.855-.757 1.522-1.626 1.788-2.735.11-.26.154-.533.181-.808l.126-.819-.016-1.881a10.17 10.17 0 01.608-4.223c.492-1.332 1.228-2.54 2.09-3.615 1.737-2.149 3.951-3.812 6.357-5.005a22.935 22.935 0 013.729-1.492 21.738 21.738 0 013.909-.842c2.638-.358 5.342-.241 7.909.391 1.29.289 2.532.754 3.726 1.292 1.204.53 2.297 1.271 3.346 2.027"
              transform="translate(376.611 322.314)"
            ></path>
            <path
              fill="#5997C0"
              fillRule="nonzero"
              d="M0-26.244s-.612.037-1.651.218c-.519.092-1.145.217-1.842.407-.702.175-1.462.462-2.261.795-.773.384-1.605.815-2.312 1.449-.39.277-.67.679-1.005 1.028-.248.421-.588.785-.762 1.263-.095.232-.236.444-.301.693l-.206.746c-.142.48-.23 1.131-.354 1.679l-.674 3.546a106.03 106.03 0 01-.804 3.556c-.279 1.176-.641 2.318-1.012 3.419-.76 2.191-1.726 4.222-3.035 5.671-.643.728-1.39 1.281-2.13 1.593a4.067 4.067 0 01-1.999.345 3.135 3.135 0 01-.726-.108 2.79 2.79 0 01-.513-.15L-22-.244l.436.043c.278.036.699.038 1.201-.033a3.898 3.898 0 001.658-.625c.569-.37 1.105-.931 1.586-1.629.953-1.412 1.66-3.351 2.254-5.482.306-1.066.543-2.195.814-3.338.26-1.147.494-2.324.724-3.508l.744-3.541c.141-.613.231-1.137.45-1.77l.314-.915c.103-.303.289-.574.43-.857.269-.578.698-1.046 1.075-1.52.45-.403.854-.847 1.344-1.137.915-.684 1.919-1.041 2.819-1.341 1.836-.529 3.403-.56 4.475-.524C-.6-26.375 0-26.244 0-26.244"
              transform="translate(379.693 307.632)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0-1.968l-2.064-.124c-.601-.004-1.281-.043-1.984.009-.354.002-.703.065-1.06.097-.348.062-.701.122-1.035.218-.328.11-.671.191-.965.356-.15.077-.313.132-.459.212a8.418 8.418 0 01-.414.272c-.298.151-.517.394-.764.591-.26.186-.452.425-.648.638-.178.223-.411.396-.536.605-.137.2-.276.371-.388.516-.229.288-.381.456-.381.456s.001-.212.036-.589c.021-.187.042-.42.09-.684.021-.273.154-.544.268-.858.12-.313.241-.666.485-.979.226-.321.445-.69.792-.968l.504-.45c.181-.138.387-.245.589-.363.395-.264.85-.391 1.296-.544.905-.234 1.837-.289 2.663-.166.833.092 1.564.343 2.159.599C-.621-2.601 0-1.968 0-1.968"
              transform="translate(375.051 298.495)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0-.353l-1.765-.299c-.258-.021-.525-.075-.808-.09a8.108 8.108 0 00-.865-.056c-.294-.028-.588.019-.883.013-.285.06-.587.044-.857.144-.138.036-.282.055-.417.098-.129.06-.262.106-.394.155-.278.072-.496.263-.755.373-.248.131-.451.328-.683.473-.235.144-.402.356-.602.511-.184.169-.381.314-.515.479-.14.158-.281.29-.395.403-.232.223-.384.351-.384.351s.009-.186.059-.513c.064-.319.105-.812.376-1.323.131-.256.225-.572.444-.836.216-.263.416-.578.709-.827.307-.229.592-.535.98-.69.186-.09.373-.185.566-.268.197-.069.402-.113.605-.168.407-.119.823-.11 1.227-.132.395.034.794.051 1.149.157.363.081.698.197 1 .335.311.117.571.299.818.437C-.423-1.011 0-.353 0-.353"
              transform="translate(389.61 294.112)"
            ></path>
            <path
              fill="#B66767"
              fillRule="nonzero"
              d="M0-8.983c.499.361.92.773 1.327 1.194.399.428.765.876 1.117 1.335.337.468.669.94.965 1.435.302.492.579 1 .827 1.529l.178.382-.229.211C3.091-1.893 1.979-.908.783 0c.842-1.243 1.765-2.408 2.709-3.554l-.051.593a15.425 15.425 0 01-1.093-1.35 20.678 20.678 0 01-.954-1.443 17.901 17.901 0 01-.802-1.545C.359-7.837.141-8.384 0-8.983"
              opacity="0.4"
              transform="translate(380.715 311.79)"
            ></path>
            <path
              fill="#B66767"
              fillRule="nonzero"
              d="M0 8.765c-.142-.152-.293-.34-.324-.583-.043-.119-.008-.246.011-.377.011-.139.115-.226.179-.345.069-.127.172-.184.272-.258.101-.066.206-.155.312-.199.213-.082.43-.169.654-.241l-.312.397-.391-2.208c-.135-.733-.268-1.463-.44-2.172a9.644 9.644 0 00-.289-1.038c-.111-.33-.239-.661-.42-.893-.176-.247-.357-.354-.675-.256-.306.102-.612.346-.909.593.165-.347.351-.708.716-.968.174-.139.435-.217.705-.194.259.046.502.171.67.327.67.646.919 1.419 1.174 2.164.223.755.375 1.517.471 2.283.097.767.145 1.53.136 2.309l-.003.337-.309.06c-.181.034-.369.053-.562.077-.094.014-.159.059-.245.086-.078.033-.183.048-.227.108-.046.054-.153.088-.171.165-.026.071-.098.141-.093.235-.042.181.003.388.07.591"
              opacity="0.4"
              transform="translate(354.6 304.009)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 5.597s-.08-.184-.169-.526a5.435 5.435 0 01-.159-1.467c.019-.618.155-1.383.628-2.108.242-.354.574-.677.962-.924.39-.244.833-.392 1.269-.481.116-.019.205-.041.343-.056l.291-.028c.234-.007.464-.003.681.019.44.041.882.154 1.265.361a3.305 3.305 0 011.53 1.651c.258.589.322 1.108.336 1.463.014.358-.024.558-.024.558s-.073-.19-.222-.497a6.462 6.462 0 00-.712-1.157c-.335-.427-.794-.819-1.355-1.033a3.387 3.387 0 00-1.394-.183l-.291.025-.236.031c-.66.114-1.236.398-1.633.863-.409.458-.648 1.056-.8 1.589a9.026 9.026 0 00-.259 1.359A7.72 7.72 0 000 5.597"
              transform="translate(367.655 300.892)"
            ></path>
            <path
              fill="#352836"
              fillRule="nonzero"
              d="M0 5.597s-.08-.184-.169-.526a5.435 5.435 0 01-.159-1.467c.019-.618.155-1.383.628-2.108.242-.354.574-.677.962-.924.39-.244.833-.392 1.269-.481.116-.019.205-.041.343-.056l.291-.028c.234-.007.464-.003.681.019.44.041.882.154 1.265.361a3.305 3.305 0 011.53 1.651c.258.589.322 1.108.336 1.463.014.358-.024.558-.024.558s-.073-.19-.222-.497a6.462 6.462 0 00-.712-1.157c-.335-.427-.794-.819-1.355-1.033a3.387 3.387 0 00-1.394-.183l-.291.025-.236.031c-.66.114-1.236.398-1.633.863-.409.458-.648 1.056-.8 1.589a9.026 9.026 0 00-.259 1.359A7.72 7.72 0 000 5.597"
              transform="translate(383.145 298.093)"
            ></path>
            <path
              fill="#B66767"
              fillRule="nonzero"
              d="M0-1.497s.678.401 1.697.862c.264.097.534.231.834.326.294.114.613.196.937.282.323.098.669.116 1.007.177.342.008.691.059 1.03-.006.347-.002.678-.092 1.004-.182.333-.069.625-.249.934-.361.292-.15.554-.343.824-.496.265-.162.47-.384.694-.549.216-.176.424-.333.573-.508l.424-.425.393-.368s-.073.178-.221.487l-.28.554c-.101.223-.261.441-.45.673-.192.228-.364.516-.633.739-.269.22-.532.492-.862.694-.35.166-.677.431-1.085.525-.396.125-.802.244-1.226.262-.422.059-.837-.006-1.246-.035A6.436 6.436 0 013.2.855a5.593 5.593 0 01-.993-.454c-.311-.149-.566-.356-.813-.519C.438-.833 0-1.497 0-1.497"
              opacity="0.4"
              transform="translate(374.96 316.549)"
            ></path>
            <path
              fill="none"
              fillRule="nonzero"
              stroke="#fff"
              strokeWidth="1"
              d="M3.91-9.475c1.468.597 2.694 1.964 3.166 3.76.747 2.838-.67 5.672-3.166 6.329s-5.125-1.112-5.872-3.95c-.664-2.523.381-5.042 2.374-6.033"
              transform="scale(-1 1) rotate(14.746 -1424.66 -1224.117)"
            ></path>
            <path
              fill="#FFA393"
              fillRule="nonzero"
              d="M0-35.573c.258-4.158.514-10.425-.28-10.533-1.189-.16-2.173 7.611-2.173 7.611s.06-10.265-.962-10.655c-.999-.383-2.078 9.795-2.124 10.238h-.003c-.018-.454-.47-11.518-1.984-10.856-1.009.442-1.14 5.42-1.11 8.818-3.239-16.765-2.676.965-2.454 6.281a28.78 28.78 0 00-.183 1.163c-10.403-10.457-3.189.732-.601 4.599-1.445 7.98-1.631 18.655-3.03 24.646l-25.93-23.435-9.337 16.075S-19.745 22.142-6.13 14.413C-.774 11.372-.054-23.971-.073-30.971c.14-2.144.183-3.769.073-4.602"
              transform="translate(444.065 364.989)"
            ></path>
            <path
              fill="#7F5E6A"
              fillRule="nonzero"
              d="M0 36.926c-3.918-3.485-6.546-6.124-6.546-6.124l-11.728-6.475S-21.305 0-5.393 5.573c5.267 1.845 11.525 6.962 16.666 11.995C9.531 25.268 5.277 31.336 0 36.926"
              transform="translate(405.877 330.044)"
            ></path>
            <path
              fill="#7F5E6A"
              fillRule="nonzero"
              d="M0 8.062l10.333 24 30 1L48 2.395l-7.661-21.146-14.003-1.388.116-4.861-20.716.463.347 6.365-9.953 3.703L0 8.062z"
              transform="translate(354.36 353.66)"
            ></path>
            <path
              fill="#FFD3AF"
              fillRule="nonzero"
              d="M17.628-4.345C2.937 5.363-10.136 35.106-10.136 35.106l27.764 29.66C33.555 48.57 36.949 21.252 37.451 7.56L17.628-4.345z"
              transform="scale(1 -1) rotate(-30.988 -666.517 -896.833)"
            ></path>
            <path
              fill="#FFA855"
              fillRule="nonzero"
              d="M20.846-6.091C5.427 2.413-5.305 34.36-5.305 34.36l26.151 31.961C38.013 51.449 42.735 24.308 44.329 10.7L20.846-6.091z"
              transform="scale(1 -1) rotate(-35.566 -547.651 -795.153)"
            ></path>
            <path
              fill="#000E23"
              fillRule="nonzero"
              d="M20.846-6.091C5.427 2.413-5.305 34.36-5.305 34.36l26.151 31.961C38.013 51.449 42.735 24.308 44.329 10.7L20.846-6.091z"
              opacity="0.1"
              transform="scale(1 -1) rotate(-35.566 -547.651 -795.153)"
            ></path>
            <path
              fill="#FFA855"
              fillRule="nonzero"
              d="M0 44.225a45.896 45.896 0 01-.348-.735c-9.266-20.076-9.827-42.132-9.827-42.132L36.094 0c-1.75 24-7.994 37.37-10.771 44.225H0z"
              transform="translate(351.266 440.055)"
            ></path>
            <path
              fill="#FFA855"
              fillRule="nonzero"
              d="M0 4.678c.131-.956.289-2.151.457-3.553C8-18.838 1.22-34.578-2.409-40.896c-3.773-9.348-6.517-15.35-6.517-15.35l-6.226-.252 6.135 17.258-29.667 3.333-8.449-18.438-5.902 1.852s9.41 22.299 13.124 46.281A19.999 19.999 0 00-42.25-3.99c-24.412 27.467-22.036 55.108-22.036 55.108l39.6 10.058 36.115-9.662C13.741 27.413 4.932 11.516 0 4.678"
              transform="translate(405.377 390.295)"
            ></path>
            <path
              fill="#000E23"
              fillRule="nonzero"
              d="M0-25l31.5-1.5S35.5 1.5 19 0 1-8.5 0-25"
              opacity="0.1"
              transform="translate(369.86 386.555)"
            ></path>
            <path
              fill="#FFA393"
              fillRule="nonzero"
              d="M0 8.94c.084-1.067-9.982.781-10.421.863v-.004c.43-.145 10.912-3.712 9.848-4.976-.709-.843-5.52.441-8.771 1.432 15.161-7.855-1.683-2.293-6.719-.574a28.85 28.85 0 00-1.166.153c7.081-12.938-1.606-2.85-4.581.727-8.063.874-17.842 2.21-23.985 2.566l7.66-15.717c-2.223-2.02-4.418-4.085-6.735-6.037-2.59-2.183-5.353-4.42-7.561-7.047-6.065 11.128-17.912 35.705-9.299 44.013 4.433 4.275 38.534-5.044 45.241-7.045 2.096-.473 3.667-.892 4.435-1.233 4.061-.931 10.144-2.46 10.022-3.252-.183-1.186-7.915.072-7.915.072S-.086 10.031 0 8.94"
              transform="translate(389.022 376.399)"
            ></path>
            <path
              fill="#000E23"
              fillRule="nonzero"
              d="M0-2.754c-2.59-2.182-5.353-4.42-7.561-7.048a174.461 174.461 0 00-3.735 7.25c3.762 3.72 8.665 7.597 13.683 9.6a58.167 58.167 0 002.654-.288l1.695-3.476C4.513 1.263 2.318-.801 0-2.754"
              opacity="0.1"
              transform="translate(344.152 366.525)"
            ></path>
            <path
              fill="#7F5E6A"
              fillRule="nonzero"
              d="M0-33.294c-.978.424-2.004 1.026-3.092 1.881-4.387 3.45-8.654 10.316-11.89 16.741C-10.844-7.949-4.856-3.583 1.945 0c2.581-4.566 4.213-7.913 4.213-7.913l3.245-3.574C6.033-18.637 3.617-26.419 0-33.294"
              transform="translate(348.531 373.221)"
            ></path>
            <path
              fill="#7F5E6A"
              fillRule="nonzero"
              d="M0-7.92c-1.392-4.343-3.986-8.466-4.917-12.896-2.052-.255-4.417.418-7.135 2.555-4.387 3.449-8.654 10.316-11.891 16.741 4.138 6.723 10.126 11.089 16.927 14.671 2.581-4.565 4.214-7.912 4.214-7.912L2.216-.289C1.519-2.847.808-5.4 0-7.92"
              transform="translate(357.491 360.069)"
            ></path>
          </g>
        </g>
        <path
          fill="#00728A"
          fillRule="nonzero"
          d="M0 1.18l12.318-6.14 5.049 17.458 36.738-34.662 8.402 18.355-47.053 27.153L0 1.18z"
          transform="translate(432.485 230.928)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M-2.583-1.731a2.793 2.793 0 100 5.584 2.793 2.793 0 000-5.584"
          transform="scale(1 -1) rotate(67.646 344.037 114.506)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M-1.084-.726a1.172 1.172 0 100 2.343 1.172 1.172 0 000-2.343"
          transform="scale(1 -1) rotate(67.658 339.449 111.13)"
        ></path>
        <path
          fill="#E3F2FF"
          fillRule="nonzero"
          d="M-2.512-2.597a9.587 9.587 0 00.098 5.359c.02.063-.079.08-.098.02a9.677 9.677 0 01-.094-5.413c.017-.063.11-.028.094.034"
          transform="scale(1 -1) rotate(85.329 412.544 -38.485)"
        ></path>
        <path
          fill="#00728A"
          fillRule="nonzero"
          d="M0 130.789l-79.926 24.009h-51.493l.561-77.714-6.685 28.747-36.524-17.059 22.194-65.776a35.994 35.994 0 0113.876-18.264l20.912-14.209 13.15.949 29.222-15.481 47.995 19.33L0 130.789z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 118.866 299.084)"
        ></path>
        <path
          fill="#004656"
          fillRule="nonzero"
          d="M0 18.296l-5.012-47.043-1.608 11.238a105.36 105.36 0 00-.618 24.64l.717 7.77c.337 3.65-.042 7.33-1.117 10.833l-5.597 18.249 6.55 3.06L0 18.296z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 249.724 357.872)"
        ></path>
        <path
          fill="#004656"
          fillRule="nonzero"
          d="M0-140.52c-4.575 4.401-9.313 17.313-10.143 21.934L1.27-43.281l-52.995 37.12L-42.643 0l79.926-24.009-22.532-114.247L0-140.52z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 156.149 453.882)"
        ></path>
        <path
          fill="#004656"
          fillRule="nonzero"
          d="M0-163.326l.463 3.924a33.618 33.618 0 003.557 11.561l2.376 4.575-2.588 5.086a9.464 9.464 0 00.76 9.836l1.828 2.532 8.214 62.55L14.927 0l-.218-.693a117.361 117.361 0 01-2.151-62.569l-.727-1.767a121.026 121.026 0 01-8.298-59.728c-5.181-4.322-6.11-11.928-2.118-17.37l.835-1.139a26.85 26.85 0 01-2.771-16.827L0-163.326z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 222.801 453.882)"
        ></path>
        <path
          fill="#FFA68E"
          fillRule="nonzero"
          d="M0 34.346l10.917-19.62L-20.612 0 0 34.346z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 272.321 387.856)"
        ></path>
        <path
          fill="#004456"
          fillRule="nonzero"
          d="M0-23.851L31.529-9.125 26.452 0 0-23.851z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 292.933 411.708)"
        ></path>
        <path
          fill="#FFC5C3"
          fillRule="nonzero"
          d="M0-42.353l12.328 1.105-13.855 54.68c-2.507 9.895 2.889 20.078 12.484 23.559 13.194 4.786 27.057-5.245 26.636-19.274l-1.888-62.913a55.04 55.04 0 006.04-29.948l-.264-3.196a5.31 5.31 0 00-3.414-4.53l-1.878-.71a5.312 5.312 0 00-4.451.325l-9.381 5.204.434.811a8.535 8.535 0 009.952 4.164l-8.253 7.661L0-42.353z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 308.412 396.249)"
        ></path>
        <path
          fill="#47284F"
          fillRule="nonzero"
          d="M0 43.336l-23.085-45.47a5.688 5.688 0 00-5.07-3.112h-23.592c-.648 0-1.182.321-1.519.782l-.004-.003-2.62 3.521h3.239l22.298 43.924a5.69 5.69 0 005.072 3.112h21.22l1.458 2.492 2.528-3.396A1.88 1.88 0 000 43.336"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 276.632 299.356)"
        ></path>
        <path
          fill="#3E4E6D"
          fillRule="nonzero"
          d="M0 51.336h-23.591a5.684 5.684 0 01-5.07-3.112l-23.085-45.47A1.895 1.895 0 01-50.057 0h23.592a5.685 5.685 0 015.07 3.112L1.69 48.583A1.895 1.895 0 010 51.336"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 280.849 297.505)"
        ></path>
        <path
          fill="#47284F"
          fillRule="nonzero"
          d="M0-11.215h-8.744a.949.949 0 00-.846 1.377l4.468 8.801A1.895 1.895 0 00-3.432 0h8.744a.947.947 0 00.846-1.376L1.69-10.177A1.894 1.894 0 000-11.215"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 320.617 310.616)"
        ></path>
        <path
          fill="#FFC5C3"
          fillRule="nonzero"
          d="M0-5.553l15.104-10.441a2.878 2.878 0 014.115.903L26.03-3.563c.53.895.534 2.006.013 2.906l-3.925 6.78a10.558 10.558 0 01-9.139 5.268 3.428 3.428 0 01-2.864-1.545L0-5.553z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 321.391 342.504)"
        ></path>
        <path
          fill="#FFA68E"
          fillRule="nonzero"
          d="M0-2.934l-.438-.865L-5.495.865 0-2.934z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 315.896 336.086)"
        ></path>
        <path
          fill="#FFC5C3"
          fillRule="nonzero"
          d="M0-1.612l10.723-6.691a3.992 3.992 0 014.822 6.323l-9.94 9.169a1.688 1.688 0 01-2.603-.392L-.565.67A1.689 1.689 0 010-1.612"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 326.997 329.763)"
        ></path>
        <path
          fill="#FFC5C3"
          fillRule="nonzero"
          d="M0 7.307l2.725 12.005 10.425 9.162L33.368 3.517l-6.16-24.684L0 7.307z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 235.951 262.082)"
        ></path>
        <path
          fill="#47284F"
          fillRule="nonzero"
          d="M0-13.674c.621-3.903.772-7.849.72-11.804L-24.95 1.387l2.364 10.417c4.924-.382 9.772-2.691 13.368-6.15C-3.981.618-1.14-6.499 0-13.674"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 211.001 268.002)"
        ></path>
        <path
          fill="#004656"
          fillRule="nonzero"
          d="M7.223-9.543h24.445L15.887 22.371l-13.072-.099L7.223-9.543z"
          transform="rotate(-9.693 286.418 265.061) rotate(133.1 49.925 187.262)"
        ></path>
        <path
          fill="#004656"
          fillRule="nonzero"
          d="M-8.805-22.866h8.654l19.082 9.201-22.323 3.579-5.413-12.78z"
          transform="rotate(-9.693 286.418 265.061) rotate(71.647 -98.542 296.912)"
        ></path>
        <path
          fill="#1E1E1E"
          fillRule="nonzero"
          d="M0 62.41c-5.051-2.94-8.959-7.788-10.761-13.347-1.802-5.558-1.151-11.934 1.213-17.278-8.091-2.318-14.497-9.678-15.677-18.011-1.18-8.334 2.931-17.183 10.06-21.656-.407 1.298-.049 3.378.768 4.467.816 1.089 2 1.85 3.235 2.421C-6.825 1.008-1.754.852 2.874-.331c4.629-1.183 8.944-3.322 13.325-5.226 4.381-1.906 8.953-3.609 13.726-3.834 8.095-.383 16.248 3.886 20.544 10.758 5.332-1.229 11.798.635 15.97 4.174 4.618 3.918 6.907 9.915 8.3 15.808 3.181 13.446 2.576 27.766-1.724 40.897-1.699 5.185-9.051 5.855-14.449 6.651C39.348 71.733 16.789 72.184 0 62.41"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 275.386 178.726)"
        ></path>
        <path
          fill="#1E1E1E"
          fillRule="nonzero"
          d="M0 4.264c1.384 2.963 4.388 4.958 7.571 5.704 3.185.747 6.536.377 9.711-.408 5.723-1.414 11.024-4.124 16.38-6.584C39.02.517 44.604-1.739 50.484-2.143 56.365-2.547 63.49.288 67.41 4.69 63.114-2.181 54.961-6.451 46.866-6.068c-4.772.226-9.345 1.929-13.725 3.834-4.381 1.905-8.697 4.043-13.326 5.227-4.627 1.183-9.699 1.339-14.036-.664C4.544 1.759 3.36.997 2.544-.091 1.728-1.18 1.369-3.26 1.776-4.559-.781-2.52-1.384 1.301 0 4.264"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 292.328 175.403)"
        ></path>
        <path
          fill="#FFC5C3"
          fillRule="nonzero"
          d="M0 5.57a5.247 5.247 0 01-3.436-4.216l-1.147-8.21a6.849 6.849 0 00-3.386-4.999l-1.647-.942a6.851 6.851 0 01-3.435-5.457l-1.475-20.523c-14.28 8.172-30.515 12.742-46.943 13.293a34.031 34.031 0 00-1.765 20.338l4.267 19.385c4.062 18.447 22.308 30.108 40.754 26.046C-2.757 36.882 7.93 23.519 8.601 8.425L0 5.57z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 218.068 236.454)"
        ></path>
        <path
          fill="#1E1E1E"
          fillRule="nonzero"
          d="M0-5.834c-4.338.383-7.997 2.799-10.155 6.217l21.242-1.875C8.363-4.48 4.337-6.217 0-5.834"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 246.254 226.776)"
        ></path>
        <path
          fill="#47284F"
          fillRule="nonzero"
          d="M0-6.128a2.546 2.546 0 00-2.545 2.545v1.038a2.545 2.545 0 005.09 0v-1.038A2.545 2.545 0 000-6.128"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 247.179 239.717)"
        ></path>
        <path
          fill="#1E1E1E"
          fillRule="nonzero"
          d="M0-6.282c-3.117.79-5.368 3.51-6.333 7.072l15.27-3.868C6.393-5.751 3.118-7.072 0-6.282"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 275.775 231.539)"
        ></path>
        <path
          fill="#47284F"
          fillRule="nonzero"
          d="M0-5.354a2.25 2.25 0 00-1.864 2.582l.146.907a2.253 2.253 0 004.447-.718l-.147-.907A2.251 2.251 0 000-5.354"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 272.882 243.592)"
        ></path>
        <path
          fill="#FFA68E"
          fillRule="nonzero"
          d="M0-5.638l-10.315 1.357C-9.654-1.953-7.327 0-4.917-.22-2.507-.439-.23-3.229 0-5.638"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 254.916 258.996)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M-12.306-18.202A121.941 121.941 0 00-2.855.829c-3.101.934-6.819.549-9.451-1.337-2.632-1.887-4.317-5.034-4.43-8.27-.113-3.237 1.936-7.359 4.43-9.424"
          transform="rotate(-9.693 286.418 265.061) rotate(-43.033 459.185 -196.224)"
        ></path>
        <path
          fill="#FFC5C3"
          fillRule="nonzero"
          d="M-11.807-11.807c-6.521 0-11.807 5.286-11.807 11.808 0 6.52 5.286 11.806 11.807 11.806C-5.286 11.807 0 6.521 0 .001c0-6.522-5.286-11.808-11.807-11.808"
          transform="rotate(-9.693 286.418 265.061) rotate(-90 215.471 9.374)"
        ></path>
        <path
          fill="#FFA68E"
          fillRule="nonzero"
          d="M-6.573-6.573a6.573 6.573 0 100 13.146 6.573 6.573 0 000-13.146"
          transform="rotate(-9.693 286.418 265.061) rotate(-90 218.089 11.992)"
        ></path>
        <path
          fill="#00728A"
          fillRule="nonzero"
          d="M0-107.825a19.092 19.092 0 0111.842 11.588l27.986 77.282L-2.82 0l-12.794-67.289.568-4.165A77.497 77.497 0 010-107.825"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 145.584 402.23)"
        ></path>
        <path
          fill="#FFC5C3"
          fillRule="nonzero"
          d="M0-64.706l3.594 12.408-67.527 35.067-31.803-11.683a4.37 4.37 0 00-4.981 1.451l-8.873 11.637a5.883 5.883 0 001.189 8.302l4.189 3.088a7.905 7.905 0 003.433 10.429l15.324 8.08a6.284 6.284 0 007.259-1.003l6.208-5.897 2.915.121a18.533 18.533 0 0014.262-5.808l81.528-22.898c12.702-3.567 20.044-16.823 16.328-29.482l-8.553-29.142L0-64.706z"
          transform="rotate(-9.693 286.418 265.061) matrix(-1 0 0 1 143.941 464.953)"
        ></path>
        <path
          fill="#FFA68E"
          fillRule="nonzero"
          d="M-4.477 7.701h17.721l-7.923 2.538A5.147 5.147 0 01-.303 8.51l-4.174-.809z"
          transform="rotate(-9.693 286.418 265.061) rotate(-119.651 253.477 160.666)"
        ></path>
        <path
          fill="#004456"
          fillRule="nonzero"
          d="M-37.17-17.058H.575L1.936-7.14l-38.663-6.603-.443-3.315z"
          transform="rotate(-9.693 286.418 265.061) rotate(23.963 -910.944 529.376)"
        ></path>
        <g>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M0-56.107h44.245a8.769 8.769 0 018.769 8.769v24.011a8.769 8.769 0 01-8.769 8.768h-29.19L6.879 0v-14.559H0a8.769 8.769 0 01-8.769-8.768v-24.011A8.769 8.769 0 010-56.107"
            transform="translate(299.993 287.725)"
          ></path>
        </g>
        <g>
          <path
            fill="none"
            stroke="#00728A"
            strokeLinejoin="round"
            strokeMiterlimit="1.5"
            strokeWidth="4.95"
            d="M327.475 262.194L301.763 250l25.712-20.475"
            transform="matrix(.80717 -.03874 .03874 .80717 57.991 65.948)"
          ></path>
          <circle
            cx="453.882"
            cy="454.99"
            r="10.055"
            fill="#00728A"
            transform="matrix(.80717 -.03874 .03874 .80717 57.991 65.948) matrix(.99375 0 0 .99375 -147.858 -202.46)"
          ></circle>
          <circle
            cx="453.882"
            cy="454.99"
            r="10.055"
            fill="#00728A"
            transform="matrix(.80717 -.03874 .03874 .80717 57.991 65.948) matrix(.64273 0 0 .64273 35.753 -62.91)"
          ></circle>
          <circle
            cx="453.882"
            cy="454.99"
            r="10.055"
            fill="#00728A"
            transform="matrix(.80717 -.03874 .03874 .80717 57.991 65.948) translate(77.086 11.195) scale(.55166)"
          ></circle>
        </g>
      </g>
    </g>
  </svg>
);
