import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface IframeComponentProps extends BoxProps {
  src: string;
  title?: string;
  width?: string;
  height?: string;
}

const IframeComponent: React.FC<IframeComponentProps> = ({
  src,
  title = "Iframe",
  width = "100%",
  height = "500px",
  ...rest
}) => {
  return (
    <Box
      as="iframe"
      src={src}
      title={title}
      width={width}
      height={height}
      border="none"
      {...rest}
    />
  );
};

export default IframeComponent;
