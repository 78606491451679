import { Center } from "@chakra-ui/react";
import { FC } from "react";

type Props = {
  number: number;
};

const CircleWithNumber: FC<Props> = ({ number }) => (
  <Center
    width="40px"
    height="40px"
    bg="gray.200"
    borderRadius="full"
    color="gray.600"
    fontWeight="bold"
    fontSize="lg"
    mr={{ base: 0, md: 4 }}
    ml={{ base: 0, md: 4 }}
  >
    {number}.
  </Center>
);

export default CircleWithNumber;
