import {
  Box,
  Stack,
  Text,
  Link,
  Flex,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import logo from "../../assets/logo/logo_white.png";
import { lightColor } from "../landingPage/LandingPage";

const BlogFooter = () => {
  return (
    <Box bg="gray.900" color="gray.200" py={10}>
      <Flex
        justify="space-between"
        align="center"
        maxW="1200px"
        mx="auto"
        px={6}
        flexWrap="wrap"
        direction={["column", "row"]}
      >
        {/* Logo Placeholder */}
        <Box mb={[4, 0]}>
          <Image src={logo} alt="Logo" boxSize="100px" objectFit="contain" />
        </Box>

        {/* Important Links */}
        <Stack
          direction={["column", "row"]}
          spacing={6}
          mb={[4, 0]}
          textAlign={["center", "left"]}
        >
          <Link
            target="_blank"
            href="https://wobble.hu/#contact"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Kapcsolat
          </Link>
        </Stack>

        {/* Social Media Links */}
        <Stack
          direction="row"
          spacing={4}
          justify={["center", "flex-start"]}
          mb={[4, 0]}
        >
          <IconButton
            icon={<FaFacebook />}
            as="a"
            href="https://www.facebook.com/people/Wobble-App/61566480490390/"
            aria-label="Facebook"
            bg="gray.900"
            color="white"
            target="_blank"
            size="lg"
            variant="outline"
            _hover={{ bg: "white", color: "gray.900" }}
          />
          <IconButton
            as="a"
            icon={<FaInstagram />}
            href="https://www.instagram.com/wobble_app/"
            aria-label="Instagram"
            bg="gray.900"
            color="white"
            target="_blank"
            size="lg"
            variant="outline"
            _hover={{ bg: "white", color: "gray.900" }}
          />
          <IconButton
            icon={<FaYoutube />}
            as="a"
            href="https://www.youtube.com/@WobbleAppHun/videos"
            aria-label="YouTube"
            bg="gray.900"
            color="white"
            target="_blank"
            size="lg"
            variant="outline"
            _hover={{ bg: "white", color: "gray.900" }}
          />
          {/* <IconButton
            as="a"
            icon={<FaLinkedin />}
            href="https://linkedin.com"
            aria-label="LinkedIn"
            bg="gray.900"
            color="white"
            size="lg"
            variant="outline"
            _hover={{ bg: "white", color: "gray.900" }}
          /> */}
        </Stack>

        {/* Policy Links */}
        <Stack
          direction={["column", "row"]}
          spacing={6}
          textAlign={["center", "left"]}
        >
          <Link
            href=" https://app.wobble.hu/adatvedelem"
            _hover={{ textDecoration: "underline", color: "white" }}
            isExternal
          >
            Adatkezelési tájékoztató
          </Link>
          <Link
            href=" https://app.wobble.hu/aszf"
            _hover={{ textDecoration: "underline", color: "white" }}
            isExternal
          >
            Általános Szerződési Feltételek
          </Link>
        </Stack>
      </Flex>

      <Box mt={8} textAlign="center">
        <Text fontSize="sm" color={lightColor}>
          &copy; {new Date().getFullYear()} Miller Productions. Minden jog
          fenntartva.
        </Text>
      </Box>
    </Box>
  );
};

export default BlogFooter;
